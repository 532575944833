<template>
  <span
    class="text-wrapper"
    :style="{backgroundImage: `url(${image})`, color: `${color}`}"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: 'UnderlinedText',
  props: {
    image: {
      type: String,
      default: require('@/assets/img/pricing/plans/yellow-line.svg')
    },
    color: {
      type: String,
      default: '#292f4d'
    }
  }
}
</script>

<style scoped>
.text-wrapper {
  background-repeat: no-repeat;
  background-position-y: 100%;
  background-size: contain;
}
</style>
