<template>
  <div>
    <!--    <plans-section-halloween />-->
    <!--    <plans-section-black-friday />-->
    <!--    <plans-section-end-of-year />-->
    <!--    <plans-section-valentines />-->
    <!--    <plans-section-spring-sale />-->
    <plans-section />
    <payment-guarantee-section
      :title="`${$t('pages.pricing.paymentGuarantee.daysMoneyBackGuaranteeNoQuestionsAsked', {days: '15'})}`"
      :description="`${$t('pages.pricing.paymentGuarantee.notFullySatisfiedWithIdeaBuddy', {days:'15', email: 'support@ideabuddy.com'})}`"
      :money-back-image="require('@/assets/img/pricing/payment-guarantee/badge-money-back.svg')"
    />
    <pricing-for-special-groups-section />
    <idea-is-safe-section />
    <capterra-reviews-section />
    <faq-section />
  </div>
</template>

<script>
import CapterraReviewsSection from '@/views/Payment/Components/Sections/CapterraReviews/CapterraReviewsSection'
import FaqSection from '@/views/Payment/Components/Sections/Faq/FaqSection'
import IdeaIsSafeSection from '@/views/Payment/Components/Sections/IdeaIsSafe/IdeaIsSafeSection'
import PaymentGuaranteeSection from '@/views/Payment/Components/Sections/PaymentGuarantee/PaymentGuaranteeSection'
// import PlansSectionHalloween from '@/views/Payment/Components/Sections/Plans/Halloween/PlansSectionHalloween'
// import PlansSectionBlackFriday from '@/views/Payment/Components/Sections/Plans/BlackFriday/PlansSectionBlackFriday'
import PlansSection from '@/views/Payment/Components/Sections/Plans/PlansSection'
// import PlansSectionEndOfYear from '@/views/Payment/Components/Sections/Plans/EndOfYear/PlansSectionEndOfYear.vue'
// import PlansSectionValentines from '@/views/Payment/Components/Sections/Plans/Valentines/PlansSectionValentines.vue'
// import PlansSectionSpringSale from '@/views/Payment/Components/Sections/Plans/SpringSale/PlansSectionSpringSale.vue'
import PricingForSpecialGroupsSection from '@/views/Payment/Components/Sections/PricingForSpecialGroups/PricingForSpecialGroupsSection'

export default {
  name: 'SelectPlan',

  components: {
    // PlansSectionSpringSale,
    // PlansSectionValentines,
    // PlansSectionEndOfYear,
    // PlansSectionBlackFriday,
    // PlansSectionHalloween,
    CapterraReviewsSection,
    FaqSection,
    IdeaIsSafeSection,
    PaymentGuaranteeSection,
    PlansSection,
    PricingForSpecialGroupsSection
  }
}
</script>
