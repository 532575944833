<template>
  <div class="features-section-wrapper">
    <div class="text-center">
      <h1 class="font-outfit-semi-bold">
        {{ $t('pages.pricing.textVideo.title') }}
      </h1>
    </div>

    <div class="text-center">
      <p class="font-outfit-medium features-section-description">
        {{ $t('pages.pricing.textVideo.description') }}
      </p>
    </div>

    <div class="all-cards-wrapper">
      <template v-for="feature in getFeaturesData">
        <feature-section-card
          :key="feature.name"
          :class-color="feature.classColor"
          :icon="feature.icon"
          :name="$t(feature.name)"
          :title="$t(feature.title)"
          :description="$t(feature.description)"
          :video-name="feature.videoName"
          :reverse="feature.reverse"
        />
      </template>
    </div>
  </div>
</template>

<script>
import FeatureSectionCard from '@/views/Payment/Components/Sections/Features/FeatureSectionCard.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FeaturesSection',

  components: {
    FeatureSectionCard
  },

  computed: {
    ...mapGetters('features', ['getFeaturesData'])
  }
}
</script>

<style scoped lang="scss">

   .features-section-wrapper {
     max-width: 1140px;
     padding: 40px 20px 20px;
     margin: 0 auto;

     @include media-breakpoint-up($md) {
       padding: 40px 30px 20px;
     }

     @include media-breakpoint-up($xl) {
       padding: 70px 0 20px;
     }

     h1 {
       font-size: 30px;
       margin: 0 auto 20px;

       @include media-breakpoint-up($md) {
         margin: 0 auto 30px;
         max-width: 650px;
       }

       @include media-breakpoint-up($lg) {
         font-size: 36px;
         max-width: 750px;
         margin: 0 auto 40px;
       }
     }

     .features-section-description {
       font-size: 18px;
       margin-bottom: 20px;

       @include media-breakpoint-up($md) {
         margin-bottom: 30px;
       }

       @include media-breakpoint-up($lg) {
         font-size: 20px;
         margin-bottom: 60px;
       }
     }

     .all-cards-wrapper {
       padding: 20px 0;
     }
   }

</style>
