<template>
  <div class="capterra-reviews-stars-wrapper">
    <span class="excellent">Excellent</span>
    <img src="@/assets/img/payment/stars.png" alt="stars" width="120">
    <span class="based">(Based on <a href="https://www.capterra.com/p/204342/IdeaBuddy/reviews/" target="_blank" rel="noopener noreferrer">
      Capterra
    </a>  and <a
      href="https://www.getapp.com/operations-management-software/a/ideabuddy/reviews/"
      target="_blank"
      rel="noopener noreferrer"
    >
      GetApp
    </a> reviews)</span>
  </div>
</template>

<script>
export default {
  name: 'CapterraReviewStars'
}
</script>

<style scoped lang="scss">

.capterra-reviews-stars-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  margin-top: 50px;

  .excellent {
    font-size: 20px;
    font-weight: 600;
  }

  img {
    margin: 0 15px;
  }
  .based {
    font-size: 13px;
    opacity: 0.5;

    a {
      margin: 0 3px;
      color: black;
      text-decoration: underline;
    }
  }
}

</style>
