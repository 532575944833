<template>
  <div>
    <!-- Loader -->
    <div
      v-show="loading"
      class="loader-price justify-content-center align-items-center"
      :style="{'min-height': minHeight}"
    >
      <loader-payment />
    </div>
    <!-- Loader -->
    <!-- Cross Price Wrapper -->
    <div
      v-show="!loading"
      :style="{'min-height': minHeight}"
      class="cross-price-wrapper"
      :class="{'dark': dark, 'd-flex align-items-center justify-content-center': isPeriodMonthly}"
    >
      <!-- Cross Price -->
      <div
        v-show="!isPeriodMonthly"
        ref="priceCrossRender"
        class="cross-price"
      >
        <span>{{ currency }}</span>
        <div class="price-without-currency">
          <div class="number">
            {{ finalCrossPrice }}
          </div>
          <div ref="priceCrossLineRender" class="cross-line" />
        </div>
      </div>
      <!-- Cross Price -->

      <!-- Regular Price -->
      <div
        ref="priceMainRender"
        class="d-flex justify-content-center animation"
      >
        <span class="price-sign font-outfit-semi-bold align-self-end">{{ currency }}</span>
        <p class="price-value position-relative d-inline-block font-outfit-medium m-0">
          {{ price }}
        </p>
      </div>
      <!-- Regular Price -->

      <!-- Price Billed -->
      <div
        v-show="!isPeriodMonthly"
        ref="priceBilledRender"
        class="animation"
      >
        <p class="price-period-desc mt-1 mb-0">
          <span
            v-if="isPeriodLifetime"
            class="text-uppercase text-yellow font-outfit-semi-bold"
          >
            {{ $t('pages.pricing.oneTimePayment') }}
          </span>
          <span
            v-else
            :class="{ 'invisible': isPeriodMonthly }"
          >
            {{ $t('pages.pricing.billedAnnually') }}
          </span>
        </p>
      </div>
      <!-- Price Billed -->
    </div>
    <!-- Cross Price Wrapper -->
  </div>
</template>

<script>
import { priceAnimation, priceMonthlyAnimation } from '@/helpers/animations'
import LoaderPayment from '@/components/LoaderPayment'

export default {
  name: 'PlanCardPrice',
  components: { LoaderPayment },

  props: {
    period: {
      type: String,
      required: true
    },

    price: {
      validator: value => typeof value === 'number' || value === null,
      required: true
    },

    monthlyPrice: {
      validator: value => typeof value === 'string' || value === null,
      default: null
    },

    currency: {
      validator: value => typeof value === 'string' || value === null,
      required: true
    },

    loading: {
      type: Boolean,
      default: true
    },

    dark: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isPeriodLifetime () {
      return this.period === 'lifetime'
    },

    isPeriodMonthly () {
      return this.period === 'monthly'
    },

    finalCrossPrice () {
      if (this.isPeriodLifetime) {
        return this.price * 9
      }

      return this.monthlyPrice ? this.monthlyPrice : null
    },

    minHeight () {
      return '127px'
    }
  },

  watch: {
    loading: {
      handler (value) {
        if (!value) {
          const { priceCrossRender, priceCrossLineRender, priceMainRender, priceBilledRender } = this.$refs
          priceAnimation(priceCrossRender, priceCrossLineRender, priceMainRender, priceBilledRender)
        }
      }
    },

    period: {
      handler () {
        const { priceCrossRender, priceCrossLineRender, priceMainRender, priceBilledRender } = this.$refs
        this.isPeriodMonthly
          ? priceMonthlyAnimation(priceMainRender)
          : priceAnimation(priceCrossRender, priceCrossLineRender, priceMainRender, priceBilledRender)
      }
    }
  }
}
</script>

<style scoped lang="scss">
$tmp-color-blue: #0e64e6;

.animation {
  visibility: hidden;
}

.loader-price {
  display: flex;
  margin: 10px 0;
}

.cross-price-wrapper {
  margin: 10px 0;

  &.dark {
    .price {
      &-sign,
      &-value {
        color: $color-yellow;
      }
    }
  }

  .cross-price {
    visibility: hidden;
    display: flex;
    justify-content: center;
    color: #bbc9e4;
    padding-bottom: 12px;
    font-size: 26px;
    line-height: 20px;
    min-height: 25px;

    span {
      font-size: 15px;
      align-self: end;
    }

    .price-without-currency {
      position: relative;
      width: fit-content;

      .number {
        width: fit-content;
      }

      .cross-line {
        position: absolute;
        visibility: hidden;
        width: fit-content;
        top: 35%;
        &:after {
          position: absolute;
          content: '';
          display: block;
          margin: 0 auto;
          height: 2px;
          background: #bbc9e4;
          width: 100%;
          right: 0;
          top: 25%;
        }
      }
    }
  }

  .price {
    &-sign {
      color: $tmp-color-blue;
      font-size: 25px;

      @include media-breakpoint-up($md) {
        font-size: 20px;
      }

      @include media-breakpoint-up($lg) {
        font-size: 25px;
      }
    }

    &-value {
      color: $tmp-color-blue;
      font-size: 66px;
      line-height: 1;

      @include media-breakpoint-up($md) {
        font-size: 45px;
      }

      @include media-breakpoint-up($lg) {
        font-size: 55px;
      }
    }

    &-text {
      color: rgba(8, 30, 74, 0.5);
      font-size: 12px;
      font-family: $outfit-light;
      width: max-content;
      bottom: 5px;
      left: 100%;

      @include media-breakpoint-up($md) {
        font-size: 9px;
      }

      @include media-breakpoint-up($lg) {
        font-size: 12px;
      }
    }

    &-period-desc {
      margin-bottom: 20px;
    }

    &-no-ideas {
      margin: 40px 0 10px;
    }
  }
}
</style>
