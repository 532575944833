<template>
  <!-- Dialog Enterprise -->
  <ib-dialog
    :title="'Contact us'"
    :visible="enterpriseVisible"
    :close-on-click-modal="true"
    :modal-append-to-body="false"
    top="8vh"
    width="950px"
    @close="closeEnterprise"
  >

    <!-- Dialog Body -->
    <div class="dialog-body">

      <!-- Header -->
      <header>

        <!-- Subtitle -->
        <p class="text-lead text-center">
          Please fill out the form below to get the conversation started
        </p>
        <!-- /Subtitle -->

      </header>
      <!-- /Header -->

      <!-- Form Wrapper -->
      <div class="form-wrapper">
        <el-row>

          <!-- Form -->
          <el-col :lg="34">
            <el-form ref="form" :model="form" :rules="rules">

              <!-- First and Last Name -->
              <el-row :gutter="10">
                <el-col :sm="12">
                  <el-form-item
                    :class="{'focused': focus.firstName}" class="input-inside-label"
                    label="First Name"
                    prop="firstName"
                  >
                    <el-input
                      v-model="form.firstName"
                      type="text"
                      placeholder=" "
                      maxlength="48"
                      @blur="toggleFocus('firstName')"
                      @focus="toggleFocus('firstName')"
                    />
                  </el-form-item>
                </el-col>

                <el-col :sm="12">
                  <el-form-item
                    :class="{'focused': focus.lastName}" class="input-inside-label"
                    label="Last Name"
                    prop="lastName"
                  >
                    <el-input
                      v-model="form.lastName"
                      placeholder=" "
                      maxlength="48"
                      @blur="toggleFocus('lastName')"
                      @focus="toggleFocus('lastName')"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- /First and Last Name -->

              <!-- Organization Name -->
              <el-row :gutter="10">
                <el-col>
                  <el-form-item
                    :class="{'focused': focus.organizationName}" class="input-inside-label"
                    label="Organization Name"
                    prop="organizationName"
                  >
                    <el-input
                      v-model="form.organizationName"
                      placeholder=" "
                      maxlength="100"
                      @blur="toggleFocus('organizationName')"
                      @focus="toggleFocus('organizationName')"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- /Email -->

              <!-- Email -->
              <el-row :gutter="10">
                <el-col>
                  <el-form-item
                    :class="{'focused': focus.email}" class="input-inside-label"
                    label="Email"
                    prop="email"
                  >
                    <el-input
                      v-model="form.email"
                      type="email"
                      placeholder=" "
                      @input="onInput()"
                      @blur="toggleFocus('email')"
                      @focus="toggleFocus('email')"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- /Email -->

              <!-- Description -->
              <el-row :gutter="10">
                <el-col>
                  <el-form-item prop="description" label="Description">
                    <el-input
                      v-model="form.description"
                      :rows="4"
                      placeholder=" "
                      resize="none"
                      type="textarea"
                      maxlength="500"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- /Description -->

            </el-form>
          </el-col>
          <!-- /Form -->
        </el-row>
      </div>
      <!-- /Form Wrapper -->

    </div>
    <!-- /Dialog Body -->

    <template #footer>
      <ib-divider class="mb-4" block />

      <!-- Terms & Conditions -->
      <p class="text-center limited terms-and-conditions pb-3">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
        <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
      </p>
      <!-- /Terms & Conditions -->

      <ib-dialog-footer
        button-size="md"
        :loading="loading"
        :submit-btn-text="$t('confirm')"
        :cancel-btn-text="$t('close')"
        :disable-submit-btn="!isAddEnabled"
        space-between-buttons
        @submit="confirmForm"
        @cancel="closeEnterprise"
      />
    </template>

  </ib-dialog>
  <!-- /Dialog -->
</template>

<script>
import '@/options/googleRecaptcha'

export default {
  name: 'DialogEnterprise',

  props: {
    enterpriseVisible: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      default: 'enterprise'
    }
  },

  data () {
    const validateFirstName = (rule, value, callback) => {
      if (/^[^-\s][a-zA-Z_\s-,.']+$/i.test(value) && value.length > 1) {
        callback()
      } else {
        callback(new Error('First name should only contain letters and must be at least 2 characters'))
      }
    }

    const validateLastName = (rule, value, callback) => {
      if (/^[^-\s][a-zA-Z_\s-,.']+$/i.test(value) && value.length > 1) {
        callback()
      } else {
        callback(new Error('Last name should only contain letters and must be at least 2 characters'))
      }
    }

    const validateOrganizationName = (rule, value, callback) => {
      if (/^[^\s].+[^\s]$/i.test(value) && value.length > 1) {
        callback()
      } else {
        callback(new Error(this.$t('validations.enterOrganizationName')))
      }
    }

    const validateDescription = (rule, value, callback) => {
      if (/^[^\s].+[^\s]$/i.test(value) && value.length > 1) {
        callback()
      } else {
        callback(new Error(this.$t('validations.enterShortDescription')))
      }
    }

    return {
      loading: false,
      form: {
        firstName: '',
        lastName: '',
        organizationName: '',
        email: '',
        description: ''
      },
      focus: {
        firstName: false,
        lastName: false,
        organizationName: false,
        email: false,
        description: false
      },
      rules: {
        email: [
          { required: true, message: this.$t('validations.enterYourEmail'), trigger: 'submit' },
          { type: 'email', message: this.$t('validations.enterValidEmail'), trigger: 'submit' }
        ],
        firstName: [
          { required: true, message: this.$t('validations.enterFirstName'), trigger: 'submit' },
          { validator: validateFirstName, trigger: 'submit' }
        ],
        lastName: [
          { required: true, message: this.$t('validations.enterLastName'), trigger: 'submit' },
          { validator: validateLastName, trigger: 'submit' }
        ],
        organizationName: [
          { required: true, message: this.$t('validations.enterOrganizationName'), trigger: 'submit' },
          { validator: validateOrganizationName, trigger: 'submit' }
        ],
        description: [
          { required: true, message: this.$t('validations.enterShortDescription'), trigger: 'submit' },
          { validator: validateDescription, trigger: 'submit' }
        ]
      }
    }
  },

  computed: {
    isAddEnabled () {
      const enabled = this.form.firstName && this.form.lastName &&
        this.form.organizationName && this.form.email && this.form.description

      return enabled
    }
  },

  methods: {
    onInput () {
      this.$refs.form.clearValidate()
    },

    closeEnterprise () {
      this.$emit('close-enterprise')
      this.resetForm()
    },

    toggleFocus (input) {
      this.focus[input] = !this.focus[input]

      if (this.form[input] !== null && this.form[input] !== '') {
        this.focus[input] = true
      }
    },

    resetForm () {
      this.form = {
        firstName: '',
        lastName: '',
        organizationName: '',
        email: '',
        description: ''
      }
      this.focus = {
        firstName: false,
        lastName: false,
        organizationName: false,
        email: false,
        description: false
      }
    },

    confirmForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          const type = this.type
          this.$recaptcha('login').then(token => {
            this.$http.post('story-mode/common/enterprise', { type, token, ...this.form })
              .then((response) => {
                if (response.status === 200) {
                  this.resetForm()
                  this.closeEnterprise()
                }
              }).finally(() => {
                this.loading = false
              })
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.input-inside-label {
  ::v-deep {
    .el-form-item__content {
      .el-input {
        input {
          border-color: #dcdfe6;
        }
      }
    }
  }
}
</style>
