<template>
  <div class="faq-wrapper">
    <div class="content-container m-auto">
      <div class="font-tragic-marker text-center faq-preheader">
        {{ $t('pages.pricing.faq.doYouHaveAnyConcerns') }}
      </div>
      <h1 class="d-lg-none text-center faq-header">
        {{ $t('pages.pricing.faq.hereSTheListOf') }}
        {{ $t('pages.pricing.faq.frequentlyAskedQuestions') }}
      </h1>
      <h1 class="d-none d-lg-block text-center faq-header">
        {{ $t('pages.pricing.faq.hereSTheListOf') }}
        <underlined-text class="px-1 py-2">
          {{ $t('pages.pricing.faq.frequentlyAskedQuestions') }}
        </underlined-text>
      </h1>
      <el-row :gutter="20">
        <el-col :md="12">
          <el-collapse>
            <el-collapse-item :title="$t('pages.pricing.faq.howDoesTheFreeTrialWork')" name="1">
              {{ $t('pages.pricing.faq.whenYouSignUpForTheFreeTrial') }}
            </el-collapse-item>
            <el-collapse-item :title="$t('pages.pricing.faq.whatWillHappenAfterMyFreeTrialEnds')" name="2">
              {{ $t('pages.pricing.faq.afterYourTrialIsUp') }}
            </el-collapse-item>
            <el-collapse-item :title="$t('pages.pricing.faq.whereCanIEnterMyCouponPromoCode')" name="3">
              {{ $t('pages.pricing.faq.aCouponPromoCode') }}
            </el-collapse-item>
            <el-collapse-item :title="$t('pages.pricing.faq.whichPaymentTypesAreSupported')" name="4">
              {{ $t('pages.pricing.faq.weAcceptPurchasesVia') }}
              <el-row>
                <el-col :span="12">
                  <ul>
                    <li>Mastercard</li>
                    <li>Visa</li>
                    <li>Maestro</li>
                    <li>American Express</li>
                    <li>Discover</li>
                    <li>Diners Club</li>
                  </ul>
                </el-col>
                <el-col :span="12">
                  <ul>
                    <li>JCB</li>
                    <li>PayPal</li>
                    <li>Apple Pay (The Apple Pay option will only appear at checkout for one-time payments to customers using the Safari browser with Apple Pay set up on their device)</li>
                    <li>Wire Transfers (ACH/SEPA/BACS)</li>
                  </ul>
                </el-col>
              </el-row>
            </el-collapse-item>
            <el-collapse-item :title="$t('pages.pricing.faq.howDoYouDefineAnIdeaSlot')" name="9">
              {{ $t('pages.pricing.faq.anIdeaSlotCanBeAnyType') }}
            </el-collapse-item>
            <el-collapse-item :title="$t('pages.pricing.faq.isAiAssistanceAvailableWithAnyPlan')" name="10">
              {{ $t('pages.pricing.faq.aIAssistanceIsAvailableWithAllPlans') }}
            </el-collapse-item>
          </el-collapse>
        </el-col>
        <el-col :md="12">
          <el-collapse>
            <el-collapse-item :title="$t('pages.pricing.faq.whatDoesTheEnterprisePlanInclude')" name="5">
              {{ $t('pages.pricing.faq.theEnterprisePlanIsTailoredToYourNeeds') }}
            </el-collapse-item>
            <el-collapse-item :title="$t('pages.pricing.faq.whatIfIWantToUpgradeToAHigherPlan')" name="6">
              {{ $t('pages.pricing.faq.ifYouDecideToUpgradeToAHigherPlan') }}
            </el-collapse-item>
            <el-collapse-item :title="$t('pages.pricing.faq.whatIfIMNotSatisfiedWithMyPurchase')" name="7">
              {{ $t('pages.pricing.faq.weHaveA15DayRefundPolicy') }}
            </el-collapse-item>
            <el-collapse-item :title="$t('pages.pricing.faq.whatWillHappenWhenICancelMyAccount')" name="8">
              {{ $t('pages.pricing.faq.whenYouDecideToTerminateYourSubscription') }}
            </el-collapse-item>
            <el-collapse-item :title="$t('pages.pricing.faq.whatIsTheValidityOfAi')" name="11">
              {{ $t('pages.pricing.faq.theValidityOfAiRequest') }}
            </el-collapse-item>
            <el-collapse-item :title="$t('pages.pricing.faq.howToRefillTheCredit')" name="12">
              <span v-html="$t('pages.pricing.faq.youCanEasilyRefillTheCredit')" />
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import UnderlinedText from '@/components/_v2/UnderlinedText'

export default {
  name: 'FaqSection',
  components: { UnderlinedText }
}
</script>

<style scoped lang="scss">
$tmp-color-blue: #0E64E6;
$tmp-color-white: #F8FAFF;

.faq-wrapper {
  padding-top: 4rem;
  background-color: $tmp-color-white;

  @include media-breakpoint-up($lg) {
    padding-top: 9rem;
  }

  .content-container {
    max-width: 1080px;
    padding: 0 20px 53px;

    @include media-breakpoint-up($md) {
      padding: 0 30px 37px;
    }

    @include media-breakpoint-up($lg) {
      padding-bottom: 60px;
    }

    .faq-preheader {
      font-size: 25px;
      color: $tmp-color-blue;

      @include media-breakpoint-up($lg) {
        font-size: 30px;
      }
    }

    .faq-header {
      font-size: 30px;
      font-family: 'outfit-semi-bold', Arial, sans-serif;
      margin: 25px 0 33px;

      @include media-breakpoint-up($md) {
        margin: 25px 0 48px;
      }

      @include media-breakpoint-up($lg) {
        font-size: 36px;
        margin: 30px 0 70px;
      }
    }

    ::v-deep {
      .el-collapse {
        border-top: unset;
        border-bottom: unset;

        &-item {
          border: 1px solid rgba(187, 201, 228, 0.33);
          border-radius: 8px;
          background-color: $color-white;
          margin-bottom: 20px;

          &__header {
            font-family: $outfit-semi-bold;
            font-size: 14px;
            padding: 15px 20px 15px 10px;
            border-bottom: unset;

            @include media-breakpoint-up($lg) {
              font-size: 18px;
              padding: 20px 30px;
            }
          }

          &__wrap {
            border-bottom: unset;
          }

          &__content {
            padding: 0 30px 36px;
            font-size: 14px;

            @include media-breakpoint-up($lg) {
              font-size: 16px;
            }
          }

          &__arrow {
            margin-right: 0;
            color: $tmp-color-blue;
            font-weight: 900;

            //&:not(.is-active) {
            //  transform: rotate(-90deg);
            //}
          }
        }
      }
    }
  }
}
</style>
