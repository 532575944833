<template>
  <ib-dialog
    :visible="visible"
    width="550px"
    top="8vh"
    @close="$emit('close')"
  >
    <!-- Dialog Content -->
    <ib-dialog-confirmation-content
      class="confirmation-content"
      :image="require('@/assets/img/illustrations/dialogs/upgrade.svg')"
      :title="$t('dialogs.freeTrialExpired.title')"
      :subtitle="$t('dialogs.freeTrialExpired.subtitle')"
    />
    <!-- /Dialog Content -->
  </ib-dialog>
</template>

<script>
export default {
  name: 'DialogExpiredFreeTrial',

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>
