import _has from 'lodash/has'
import pricesInEuros from '@/data/pricesInEuros'

const currencies = [
  {
    name: 'US Dollar (USD)',
    key: 'US$',
    displayKey: '$'
  },
  {
    name: 'Australian Dollar (AUD)',
    key: 'A$',
    displayKey: 'A$'
  },
  {
    name: 'Brazilian Real (BRL)',
    key: 'R$',
    displayKey: 'R$'
  },
  {
    name: 'Canadian Dollar (CAD)',
    key: 'CA$',
    displayKey: 'CA$'
  },
  {
    name: 'Euro (EUR)',
    key: '€',
    displayKey: '€'
  },
  {
    name: 'Indian Rupee (INR)',
    key: '₹',
    displayKey: '₹'
  },
  {
    name: 'Mexican Pesos (MXN)',
    key: 'MX$',
    displayKey: 'MX$'
  },
  {
    name: 'New Zealand Dollar (NZD)',
    key: 'NZ$',
    displayKey: 'NZ$'
  },
  {
    name: 'Pound Sterling (GBP)',
    key: '£',
    displayKey: '£'
  },
  {
    name: 'South African Rand (ZAR)',
    key: 'ZAR',
    displayKey: 'ZAR'
  },
  {
    name: 'Turkish Lira (TRY)',
    key: 'TRY',
    displayKey: 'TRY'
  },
  {
    name: 'Polish Zloty (PLN)',
    key: 'PLN',
    displayKey: 'PLN'
  }
]

const formatPrice = (price) => {
  let formattedPrice = price
  let formattedCurrency = null

  currencies.forEach((item) => {
    if (price.includes(item.key)) {
      formattedPrice = price.toString().replace(item.key, '').replace(',', '').trim()
      formattedCurrency = item.displayKey
    }
  })

  return {
    price: formattedPrice,
    currency: formattedCurrency
  }
}

const getPriceInEuro = (plan) => {
  if (_has(pricesInEuros, plan)) {
    return pricesInEuros[plan[0]][plan[1]][plan[2]][plan[3]]
  }

  return null
}

export { formatPrice, getPriceInEuro }
