<template>

  <!-- Select Plan -->
  <div id="payment">
    <payment-header :page-component="pageComponent" :prev-route="prevRoute" />
    <component :is="pageComponent" :data="data" @next-component="activeComponent" @step-back="stepBack" />

    <!-- Dialog Expired FreeTrial -->
    <dialog-expired-free-trial
      :visible="dialogExpiredFreeTrial"
      @close="dialogExpiredFreeTrial = false"
    />
    <!-- /Dialog Expired FreeTrial -->
  </div>
  <!-- /Select Plan -->

</template>

<script>
import DialogExpiredFreeTrial from '@/views/Home/Components/Dialogs/DialogExpiredFreeTrial'
import IdeasAndUsers from '@/views/Payment/IdeasAndUsers'
import Login from '@/views/Payment/Login'
import MixinPaddle from '@/mixins/paddle'
import PaymentHeader from '@/views/Payment/Components/Header'
import SelectLifetimePlan from '@/views/Payment/SelectLifetimePlan'
import SelectPlan from '@/views/Payment/SelectPlan'
import SignUp from '@/views/Payment/SignUp'

import { mapGetters } from 'vuex'

const publicRouteComponents = {
  payment: 'SignUp',
  'payment-login': 'Login',
  'payment-lifetime-deal': 'SignUp',
  'payment-lifetime-deal-login': 'Login'
}

const protectedRouteComponents = {
  payment: 'SelectPlan',
  'payment-lifetime-deal': 'SelectLifetimePlan'
}

export default {
  name: 'Payment',

  components: {
    SignUp,
    Login,
    SelectPlan,
    SelectLifetimePlan,
    IdeasAndUsers,
    PaymentHeader,
    DialogExpiredFreeTrial
  },

  mixins: [
    MixinPaddle
  ],

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },

  data () {
    return {
      dialogExpiredFreeTrial: false,
      data: {},
      pageComponent: 'SignUp',
      prevRoute: {}
    }
  },

  computed: {
    ...mapGetters('user', ['isLoggedIn', 'isAppSumoUser', 'isLifetimeSubscription']),

    isLifetimeDealRoute () {
      return this.$router.currentRoute.name === 'payment-lifetime-deal'
    }
  },

  watch: {
    '$route.name': {
      handler () {
        if (this.isLoggedIn) {
          this.pageComponent = protectedRouteComponents[this.$route.name]
        } else {
          this.pageComponent = publicRouteComponents[this.$route.name]
        }
      },
      immediate: true
    }
  },

  mounted () {
    this.setupPaddle()

    if (!this.$store.state.user.subscribed && this.isLoggedIn) {
      this.dialogExpiredFreeTrial = true
    }

    if (this.isLoggedIn && !this.isLifetimeDealRoute && (this.isAppSumoUser || this.isLifetimeSubscription)) {
      this.$router.push({ name: 'payment-lifetime-deal' })
    }
  },

  methods: {
    stepBack (component) {
      this.pageComponent = component
    },

    activeComponent (data) {
      if (this.isLoggedIn && data.component) {
        this.pageComponent = data.component
        this.data = data
      }
    }
  }
}
</script>
