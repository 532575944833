<template>
  <div class="wrapper">
    <div class="content-container m-auto">
      <el-row class="d-flex align-items-center flex-wrap" :gutter="20">
        <el-col :lg="10" class="paddle-wrapper mb-2">
          <div class="d-flex justify-content-center">
            <img src="@/assets/img/pricing/payment-guarantee/shield-check.svg" alt="shield check">
            <p>{{ $t('pages.pricing.paymentGuarantee.securePaymentsProvidedBy') }}</p>
            <img class="paddle-logo" src="@/assets/img/pricing/payment-guarantee/payment-paddle.svg" alt="paddle logo">
          </div>
          <div class="d-flex justify-content-center mt-2">
            <img class="payment-card" src="@/assets/img/pricing/payment-guarantee/payment-paypal.png" alt="paypal card">
            <img class="payment-card" src="@/assets/img/pricing/payment-guarantee/payment-visa.png" alt="visa card">
            <img class="payment-card" src="@/assets/img/pricing/payment-guarantee/payment-mastercard.png" alt="mastercard card">
            <img class="payment-card" src="@/assets/img/pricing/payment-guarantee/payment-american.png" alt="american express card">
          </div>
        </el-col>
        <el-col :lg="14" class="money-back-wrapper d-md-flex mt-4 mt-lg-0 text-center text-md-left px-md-4 px-lg-0">
          <img class="mr-md-3" :src="moneyBackImage" width="99" height="99" alt="money back">
          <div>
            <p class="money-back-header mt-md-0">
              {{ title }}
            </p>
            <p class="money-back-description my-0" v-html="description" />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PaymentGuaranteeSection',

  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    moneyBackImage: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  margin-top: 20px;

  @include media-breakpoint-up($lg) {
    margin-top: 56px;
  }

  .content-container {
    max-width: 1140px;
    padding: 10px 20px 20px;

    @include media-breakpoint-up($md) {
      padding: 10px 30px 0;
    }

    @include media-breakpoint-up($lg) {
      padding: 10px 30px 20px;
    }

    .paddle-wrapper {
      p {
        font-size: 12px;
        color: #081E4A;
        margin-left: 4px;
        margin-right: 2px;

        @include media-breakpoint-up($md) {
          font-size: 14px;
        }
      }

      .paddle-logo {
        width: 78px;
        height: 38px;

        @include media-breakpoint-up($md) {
          width: 92px;
          height: 45px;
        }
      }

      .payment-card {
        border-radius: 7px;
        margin: 0 5px;
        width: 56px;
        height: 39px;

        @include media-breakpoint-up($md) {
          width: 66px;
          height: 46px;
        }
      }
    }

    .money-back-wrapper {
      .money-back-header {
        font-family: $outfit-medium;
        font-size: 16px;
        margin-bottom: 10px;
      }

      .money-back-description {
        font-size: 14px;

        ::v-deep {
          span {
            color: #0E64E6;
            font-family: $outfit-medium;
          }
        }
      }
    }
  }
}
</style>
