<template>
  <div
    class="special-group-card-wrapper d-flex flex-column  align-items-center position-relative"
    :class="reverse ? 'flex-md-row-reverse' : 'flex-md-row'"
  >
    <div
      class="text-box-wrapper flex-grow-1"
      :class="reverse ? 'text-box-wrapper-revers' : null"
    >
      <div class="text-box">
        <template v-if="icon && title">
          <div class="title-wrapper d-flex align-items-center">
            <img
              :src="icon"
              alt="icon"
            >
            <p class="text-box-title">
              {{ title }}
            </p>
          </div>
        </template>

        <p class="text-box-subtitle font-outfit-semi-bold">
          {{ subtitle }}
        </p>

        <p class="text-box-description">
          {{ description }}
        </p>

        <div>
          <ib-button size="sm" @click="$emit('click')">
            {{ $t('getStarted') }}
          </ib-button>
        </div>
      </div>
    </div>
    <div class="image-wrapper position-relative">
      <img
        class="img-responsive"
        :src="image"
        :alt="title"
      >
      <slot name="card" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PricingForSpecialGroupsCard',

  props: {
    image: {
      type: String,
      required: true
    },

    icon: {
      type: String,
      default: null
    },

    title: {
      type: String,
      default: null
    },

    subtitle: {
      type: String,
      required: true
    },

    description: {
      type: String,
      required: true
    },

    reverse: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.special-group-card-wrapper {
  column-gap: 44px;
  row-gap: 30px;

  @include media-breakpoint-up($lg) {
    column-gap: 46px;
    row-gap: 60px;
  }

  .text-box-wrapper {
    @include media-breakpoint-up($md) {
      max-width: 50%;
    }

    &.text-box-wrapper-revers {
      .text-box {
        @include media-breakpoint-up($md) {
          padding: 0 0 0 20%;
        }
      }
    }

    .text-box {
      @include media-breakpoint-up($md) {
        padding: 0 20% 0 0;
      }

      .title-wrapper {
        margin: 0 0 15px;
        column-gap: 10px;

        @include media-breakpoint-up($md) {
          margin: 0 0 20px
        }

        @include media-breakpoint-up($lg) {
          font-size: 20px;
        }

        img {
          width: 22px;

          @include media-breakpoint-up($lg) {
            width: 26px;
          }
        }

        .text-box-title {
          color: #0E64E6;
          font-size: 16px;
          margin: 0;

          @include media-breakpoint-up($lg) {
            font-size: 20px;
          }
        }
      }

      .text-box-subtitle {
        font-size: 20px;
        margin: 0 0 15px;

        @include media-breakpoint-up($md) {
          font-size: 20px;
          margin: 0 0 10px;
        }

        @include media-breakpoint-up($lg) {
          font-size: 26px;
        }
      }

      .text-box-description {
        margin: 0 0 25px;
        font-size: 14px;

        @include media-breakpoint-up($lg) {
          font-size: 16px;
        }
      }
    }
  }

  .image-wrapper {
    flex-grow: 1;

    @include media-breakpoint-up($md) {
      max-width: 50%;
    }
  }
}
</style>
