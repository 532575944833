<template>
  <div class="benefit-section-wrapper d-flex flex-column flex-md-row align-items-center">
    <template v-for="benefit in getBenefitsData">
      <benefits-section-card
        :key="benefit.title"
        :image="benefit.image"
        :title="$t(benefit.title)"
        :text="$t(benefit.text)"
      />
    </template>
  </div>
</template>

<script>

import BenefitsSectionCard from '@/views/Payment/Components/Sections/Benefit/BenefitsSectionCard.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'BenefitsSection',

  components: {
    BenefitsSectionCard
  },

  computed: {
    ...mapGetters('benefits', ['getBenefitsData'])
  }
}
</script>

<style scoped lang="scss">

 .benefit-section-wrapper {
   max-width: 1140px;
   padding: 40px 20px 20px;
   margin: 0 auto;

   @include media-breakpoint-up($md) {
     padding: 40px 30px 20px;
     flex-direction: row;
     justify-content: space-around;
     flex-wrap: wrap;
   }

   @include media-breakpoint-up($xl) {
     padding: 0 0 20px;
   }
 }

</style>
