<template>
  <div class="idea-is-safe-list-item-wrapper">
    <div class="d-flex align-items-center mb-1">
      <img
        class="icon"
        :src="icon"
      >
      <p class="title d-flex align-items-center m-0 font-outfit-semi-bold">
        {{ title }}
      </p>
    </div>
    <ul>
      <li>
        <span>
          {{ description }}
          <slot name="description" />
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'IdeaIsSafeListItem',

  props: {
    icon: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    description: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
.idea-is-safe-list-item-wrapper {

  p {
    color: $color-white;
    font-size: 14px;

    @include media-breakpoint-up($lg) {
      font-size: 18px;
    }
  }

  .icon {
    margin-right: 3px;
    background-size: contain;
  }

  .title {
    padding: 0 0 0 10px;
  }

  ul {
    list-style: none;
    padding: 0 0 0 30px;
    margin: 0;

    li {
      font-size: 12px;

      @include media-breakpoint-up($lg) {
        font-size: 14px;
      }
    }
  }
}

</style>
