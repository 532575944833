<template>
  <p class="subscription-alert px-3 py-1 m-0">
    {{ text }}
  </p>
</template>

<script>
export default {
  name: 'SubscriptionAlert',

  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.subscription-alert {
  font-family: outfit-semi-bold;
  background-color: #e53935;
  color: #e0e0e0;
}
</style>
