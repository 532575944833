<template>
  <div class="lifetime-section-wrapper">
    <div class="text-wrapper">
      <div class="icon">
        <img src="@/assets/img/pricing/lifetime-deal/aiAssistanceIcon.svg" alt="ai-assistance-icon">
        <span>{{ $t('aiAssistance.title') }}</span>
      </div>
      <div class="title font-outfit-bold">
        {{ $t('aiAssistanceSection.title') }}
      </div>
      <div class="text">
        <p class="font-outfit-regular">
          {{ $t('aiAssistanceSection.text') }}
        </p>
      </div>
      <!--      <div class="link-wrapper">-->
      <!--        <div class="underline-link">-->
      <!--          <a href="https://policies.google.com/privacy">{{ $t('learnMore') }}</a>-->
      <!--          <img src="@/assets/img/pricing/lifetime-deal/arrowRight.svg" alt="arrow-right">-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="image-wrapper">
      <img src="@/assets/img/pricing/lifetime-deal/aiAssistance.png" alt="ai-assistance">
    </div>
  </div>

</template>

<script>
export default {
  name: 'AiAssistanceSection'
}
</script>

<style scoped lang="scss">
.lifetime-section-wrapper {
  max-width: 1140px;
  display: flex;
  flex-direction: column;
  background-image: asset("img/pricing/lifetime-deal/aiAssistanceBackground.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0 auto 50px;
  padding: 20px;

  @include media-breakpoint-up($md) {
    padding: 40px 30px 20px;
    flex-direction: row;
    justify-content: space-around;
    //flex-wrap: wrap;
  }

  @include media-breakpoint-up($lg) {
    background-size: contain;
  }

  @include media-breakpoint-up($xl) {
    justify-content: space-between;
    padding: 30px;
    margin: 0 auto 100px;
  }
}

.text-wrapper {
  width: 100%;
  padding: 20px;

  @include media-breakpoint-up($md) {
  width: 50%;
}

  @include media-breakpoint-up($lg) {
  max-width: 380px;
  padding: 20px 50px 20px 70px;
}

  .icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    color: $color-ai;
    font-weight: bold;
  }

  .title {
    font-size: 26px;
    font-weight: bold;
    margin: 15px 0 40px;
  }
}

.image-wrapper {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up($md) {
  width: 50%;
}

  img {
    width: 100%;
    max-width: 450px;
  }

  a {
    font-size: 15px;
  }
}

.link-wrapper {
  margin-top: 30px;

  a {
    font-size: 15px;
  }

  .underline-link {
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    transition: all .2s ease-in-out;
    column-gap: 5px;

    a {
      text-decoration: none;
      border-bottom: 2px solid $color-ai;
      color: $color-navy;
    }

    a,
    img {
      padding: 0 0 2px;
    }
  }
}
</style>
