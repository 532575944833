<template>

  <!-- Select Plan -->
  <div id="ideas-users">
    <div class="container">

      <!-- Steps Section -->
      <el-row>
        <el-col>
          <div class="steps-wrapper">
            <div class="step">
              <p>
                Sign Up
                <i class="el-icon-arrow-right" />
              </p>
            </div>
            <div class="step">
              <p>
                Choose Your Plan
                <i class="el-icon-arrow-right" />
              </p>
            </div>
            <div class="step active">
              <p>
                Ideas and Users
                <i class="el-icon-arrow-right" />
              </p>
            </div>
            <div class="step">
              <p>
                Review and Pay
                <i class="el-icon-arrow-right" />
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- /Steps Section -->

      <!-- Hero Section -->
      <el-row>
        <el-col>
          <div class="hero-section">
            <h1>
              Select number of ideas<!-- and other users-->
            </h1>
            <!--<h3>
              Try IdeaBuddy for free, decide later
            </h3>-->
          </div>
        </el-col>
      </el-row>
      <!-- /Hero Section -->

      <!-- Sub Hero Section -->
      <el-row>
        <el-col>
          <div class="sub-hero">
            All prices in USD
          </div>
        </el-col>
      </el-row>
      <!-- /Sub Hero Section -->

      <!-- Chose Cards -->
      <el-row type="flex">
        <el-col :span="14" class="card-holder">
          <p class="info-text info-text__top">
            <span>Have a coupon?</span> Wait until you reach the final payment screen, and you can enter it there.
          </p>
          <div class="card-wrapper card-wrapper__left">
            <div class="heading-wrapper">
              <h2>
                <!--Select ideas and users-->
                How many ideas you plan to have?
              </h2>
              <!--<p>
                Select the maximum number of ideas you will have in your collection
              </p>-->
            </div>

            <div class="slider-wrapper">
              <el-slider
                v-model="value"
                :step="25"
                :marks="marks"
                show-stop
                :show-tooltip="tooltipDisplay"
              />
            </div>

            <div class="info-wrapper">
              <p class="info">
                <span class="info-inner">
                  {{ record.ideas }}
                </span>
                <template v-if="record.ideas > 1">
                  Ideas
                </template>
                <template v-else>
                  Idea
                </template>
              </p>
              <!--<p class="info">
                <span class="info-inner">
                  {{ record.users }}
                </span>
                Buddies
              </p>

              <p class="info">
                <span class="info-inner">
                  Unl.
                </span>
                Guests
              </p>-->
            </div>
          </div>
          <p class="info-text info-text__bottom">
            All subscriptions will be automatically renewed from your selected payment method on a recurring basis, and we’ll send you a receipt each time. We do not store your card details. You can upgrade, downgrade, or cancel anytime. If the subscription is canceled, refunds and termination of access will follow the Terms of Use. Prices are in US Dollars and subject to change. Other restrictions and taxes may apply.
          </p>
        </el-col>

        <el-col :span="10" class="card-holder">
          <div class="card-wrapper">
            <h4>
              YOUR BILL
            </h4>

            <div class="heading-wrapper flex-heading">
              <h2>
                {{ record.slug }}
              </h2>
              <span class="price">
                <span :class="{'flat': record.price.toString().slice(-3) === '.00'}">
                  <sup class="currency">$</sup>
                  {{ typeof record !== 'undefined' ? record.price.toString().slice(0,-3) : 0 }}
                  <sup class="fraction">{{ typeof record !== 'undefined' ? record.price.toString().slice(-3) === '.00' ? '' : record.price.toString().slice(-3) : 0 }}</sup>
                </span>

                <span class="price-text">
                  <template v-if="data.type === 'month'">
                    Per Month
                  </template>

                  <template v-if="data.type === 'year'">
                    Per Year
                  </template>
                </span>
              </span>
            </div>

            <div class="info-wrapper">
              <p class="info">
                <span class="info-inner">
                  {{ record.ideas }}
                </span>
                <template v-if="record.ideas > 1">
                  Ideas
                </template>
                <template v-else>
                  Idea
                </template>
              </p>
              <!--<p class="info">
                <span class="info-inner">
                  {{ record.users }}
                </span>
                Buddies
              </p>
              <p class="info">
                <span class="info-inner">
                  Unl.
                </span>
                Guests
              </p>-->
            </div>

            <template v-if="data.type === 'month'">
              <h3>
                Billed Monthly
              </h3>

              <p class="sub-text" @click="updateType">
                Change to yearly and save 40%
              </p>
            </template>

            <template v-if="data.type === 'year'">
              <h3>
                Billed Yearly
              </h3>

              <p class="sub-text" @click="updateType">
                <span class="switch-to">
                  Switch to monthly
                </span>

                <!--<span class="switch-to__text">
                  You will save $88
                </span>-->
              </p>
            </template>

            <hr>

            <div class="bottom-card">
              <div class="left-side">
                <h3>
                  TOTAL
                </h3>
                <!--<span>
                  + taxes
                </span>-->
              </div>

              <div class="right-side">
                <span class="price">
                  <span :class="{'flat': record.price.toString().slice(-3) === '.00'}">
                    <sup class="currency">$</sup>
                    {{ typeof record !== 'undefined' ? record.price.toString().slice(0,-3) : 0 }}
                    <sup class="fraction">{{ typeof record !== 'undefined' ? record.price.toString().slice(-3) === '.00' ? '' : record.price.toString().slice(-3) : 0 }}</sup>
                  </span>

                  <template v-if="data.type === 'month'">
                    <span class="price-text">
                      Per Month
                    </span>

                    <span class="price-text">
                      + taxes
                    </span>
                  </template>

                  <template v-if="data.type === 'year'">
                    <span class="price-text">
                      Per Year
                    </span>

                    <span class="price-text">
                      <!--Billed Yearly: $99-->+ taxes
                    </span>
                  </template>
                </span>
              </div>
            </div>

            <div class="secured">
              <i class="el-icon-lock" />
              All payments are safe and secured with SSL encryption
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- /Chose Cards -->

      <!-- Review and Pay - start -->
      <el-row>
        <el-col>
          <hr>
          <div class="btn-pay-wrapper">
            <button class="back" @click="stepBack">
              Back to plans
            </button>

            <button
              class="review-pay"
              @click="$store.getters['user/isSubscribed'] &&
                $store.state.user.subscription.status !== 'deleted' &&
                !$store.state.user.freeTrial ? triggerPayment() : triggerPaddle()"
            >
              Review and Pay
            </button>
          </div>
        </el-col>
      </el-row>
      <!-- Review and Pay - end -->

      <!-- Plan change Dialog -->
      <dialog-confirm
        :visible="dialogConfirm"
        :preview-data="previewData"
        :record="record"
        :data="data"
        @close-dialog="dialogConfirm = false"
        @confirm-dialog="confirmedUpdatePlan"
      />
      <!-- /Plan change Dialog -->

      <!-- Thank You Dialog -->
      <dialog-thank-you
        :dialog-thank-you-visible="dialogThankYouVisible"
        :thank-you-data="chosedData"
        @close-dialog="closeDialog"
      />
      <!-- /Thank You Dialog -->

    </div>
  </div>
  <!-- /Select Plan -->

</template>

<script>
import DialogConfirm from '@/views/Payment/Dialogs/DialogConfirm'
import DialogThankYou from '@/views/Payment/Dialogs/DialogThankYou'
import UserPlanEnum from '@/constants/UserPlanEnum'

export default {
  name: 'IdeasAndUsers',

  components: {
    DialogThankYou,
    DialogConfirm
  },

  props: {
    data: {
      type: Object,
      default: () => ({
        type: 'month',
        slug: UserPlanEnum.DREAMER,
        payments: []
      })
    }
  },

  data () {
    return {
      confirmed: false,
      dialogConfirm: false,
      previewData: {},
      dialogThankYouVisible: false,
      chosedData: {},
      value: 0,
      marks: {
        0: '1',
        25: '3',
        50: '10',
        75: '30',
        100: '100'
      },
      tooltipDisplay: false
    }
  },

  computed: {
    record () {
      return this.data.payments.find((payment) => {
        return payment.slug === this.data.slug && payment.type === this.data.type && payment.ideas === parseInt(this.marks[this.value])
      })
    }
  },

  methods: {
    stepBack () {
      this.$emit('step-back', 'SelectPlan')
    },

    updateType () {
      // if (this.data.type === 'month') {
      //   this.data.type = 'year'
      // } else {
      //   this.data.type = 'month'
      // }
    },

    confirmedUpdatePlan () {
      this.confirmed = true
      this.triggerPayment()
    },

    triggerPayment () {
      if (this.$store.state.user.subscription.id === this.record.id) {
        this.$notify({ message: 'You already have this plan!', type: 'error' })
      } else {
        this.$http.post(
          'provider/update-plan',
          { ...this.record, confirmed: this.confirmed }
        ).then((response) => {
          if (response.status === 200) {
            this.previewData = response.data.payload
            this.dialogConfirm = true

            if (this.confirmed) {
              this.$store.commit('user/setSubscription', response.data.payload)
              this.$intercom.trackEvent('Updated subscription')
              this.chosedData = Object.assign({}, this.record)
              this.dialogThankYouVisible = true
              this.previewData = {}
              this.dialogConfirm = false
            }

            if (!this.previewData.success) {
              this.previewData = {}
              this.dialogConfirm = false
              this.triggerPaddle()
            }
          }
        })
      }
    },

    triggerPaddle () {
      if (this.$store.state.user.subscription.status !== 'deleted' && this.$store.state.user.subscription.id === this.record.id) {
        this.$notify({ message: 'You already have this plan!', type: 'error' })
      } else {
        const userPlanInfo = this.record.slug + this.record.ideas + (this.record.type === 'month' ? 'Monthly' : 'Annual')
        const startUserPlan = userPlanInfo + 'Start'

        this.$gtm.trackEvent({
          event: startUserPlan
        })

        const $this = this
        // eslint-disable-next-line no-undef
        Paddle.Checkout.open({
          product: this.record.plan_id,
          email: this.$store.state.user.email,
          passthrough: this.$store.state.user.id,
          successCallback: (data) => {
            this.$http.post('provider/select-plan', this.record).then((response) => {
              if (response.status === 200) {
                // Send GTM events
                const successCheckout = 'checkout' + userPlanInfo + 'Success'
                $this.$gtm.trackEvent({
                  event: successCheckout
                })

                this.$intercom.update({
                  PlanType: this.record.slug,
                  PlanExpiry: response.data.payload.subscription.nextBillDate,
                  MaxIdeas: response.data.payload.subscription.ideas,
                  subscription_updated_at: this.$moment().unix()
                })
                this.$intercom.trackEvent('New subscription')

                this.$store.commit('user/setSubscription', response.data.payload)
                this.chosedData = Object.assign({}, this.record)
                this.dialogThankYouVisible = true
              }
            })
          },
          closeCallback: function (data) {
            // Send GTM events
            const closeCheckout = 'checkout' + userPlanInfo + 'Close'
            $this.$gtm.trackEvent({
              event: closeCheckout
            })
          }
        })
      }
    },

    closeDialog () {
      this.dialogThankYouVisible = false
      this.$router.push({ name: 'home' })
    }
  }
}
</script>
