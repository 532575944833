<template>
  <div class="pricing-for-special-groups-wrapper position-relative">
    <div class="pricing-for-special-groups">
      <h1 class="title text-center font-outfit-semi-bold">
        {{ $t('pages.pricing.pricingForSpecialGroups.pricingFor') }}
        <underlined-text class="underlined-text">
          {{ $t('pages.pricing.pricingForSpecialGroups.specialGroups') }}
        </underlined-text>
      </h1>
      <div class="cards-wrapper m-auto">
        <pricing-for-special-groups-card
          class="special-groups-card"
          :image="require('@/assets/img/pricing/pricing-for-special-groups/boy-with-phone.webp')"
          :subtitle="$t('pages.pricing.pricingForSpecialGroups.enterprisePlan.subtitle')"
          :description="$t('pages.pricing.pricingForSpecialGroups.enterprisePlan.description')"
          @click="onEnterpriseContact"
        />
      </div>
      <div class="cards-wrapper m-auto">
        <pricing-for-special-groups-card
          :image="require('@/assets/img/pricing/pricing-for-special-groups/woman.png')"
          :subtitle="$t('pages.pricing.pricingForSpecialGroups.schoolsSection.subtitle')"
          :description="$t('pages.pricing.pricingForSpecialGroups.schoolsSection.description')"
          reverse
          @click="onSchoolsContact"
        />
      </div>
    </div>

    <!-- Dialog Enterprise -->
    <dialog-enterprise
      :enterprise-visible="dialogEnterpriseVisible"
      :type="dialogEnterpriseType"
      @close-enterprise="onDialogEnterpriseClose"
    />
    <!-- /Dialog Enterprise -->
  </div>
</template>

<script>
import DialogEnterprise from '@/views/Payment/Dialogs/DialogEnterprise'
import PricingForSpecialGroupsCard from '@/views/Payment/Components/Sections/PricingForSpecialGroups/PricingForSpecialGroupsCard'
import UnderlinedText from '@/components/_v2/UnderlinedText'

export default {
  name: 'PricingForSpecialGroupsSection',

  components: {
    DialogEnterprise,
    PricingForSpecialGroupsCard,
    UnderlinedText
  },

  data () {
    return {
      dialogEnterpriseVisible: false,
      dialogEnterpriseType: null
    }
  },

  methods: {
    onEnterpriseContact () {
      this.dialogEnterpriseType = 'enterprise'
      this.dialogEnterpriseVisible = true
    },

    onSchoolsContact () {
      this.dialogEnterpriseType = 'schools'
      this.dialogEnterpriseVisible = true
    },

    onDialogEnterpriseClose () {
      this.dialogEnterpriseVisible = false
      this.dialogEnterpriseType = null
    }
  }
}
</script>

<style scoped lang="scss">
.pricing-for-special-groups-wrapper {
  background-color: #F8FAFF;
  background-image: url('~@/assets/img/pricing/pricing-for-special-groups/wave.svg');
  background-repeat: no-repeat;
  background-size: contain;
  padding: 20px 20px 0;

  .pricing-for-special-groups {
    max-width: 1020px;
    margin: 0 auto;

    @include media-breakpoint-up($md) {
      padding: 45px 30px 0;
    }

    @include media-breakpoint-up($lg) {
      padding: 90px 30px 0;
    }

    .title {
      font-size: 30px;
      margin-bottom: 33px;

      @include media-breakpoint-up($md) {
        margin-bottom: 65px;
      }

      @include media-breakpoint-up($lg) {
        font-size: 36px;
        margin-bottom: 96px;
      }
    }

    .cards-wrapper {
      max-width: 1140px;

      .special-groups-card {
        margin-bottom: 31px;

        @include media-breakpoint-up($md) {
          margin-bottom: 58px;
        }
      }
    }
  }
}
</style>
