export default {
  methods: {
    setupPaddle (callback) {
      const paddleUrl = 'https://cdn.paddle.com/paddle/paddle.js'

      if (document.querySelector('script[src*=\'https://cdn.paddle.com/paddle/paddle.js\']') === null) {
        const paddleScript = document.createElement('script')
        paddleScript.type = 'text/javascript'
        paddleScript.src = paddleUrl
        paddleScript.onload = () => {
          if (process.env.VUE_APP_PADDLE_SANDBOX === 'true') {
            // eslint-disable-next-line no-undef
            Paddle.Environment.set('sandbox')
          }
          // eslint-disable-next-line no-undef
          Paddle.Setup({ vendor: parseInt(process.env.VUE_APP_PADDLE_VENDOR_ID) })

          if (typeof callback === 'function') {
            callback()
          }
        }

        document.head.appendChild(paddleScript)

        return
      }

      // If Paddle is already loaded
      if (typeof callback === 'function') {
        callback()
      }
    }
  }
}
