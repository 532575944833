import i18n from '@/i18n'

export default {
  jonathan: {
    title: i18n.t('pages.pricing.capterraReviews.jonathan.title'),
    description: i18n.t('pages.pricing.capterraReviews.jonathan.description'),
    link: 'https://www.capterra.com/p/204342/IdeaBuddy/reviews/3339144',
    user: {
      avatar: require('@/assets/img/pricing/capterra-reviews/jonathan.png'),
      name: 'Jonathan C.',
      title: 'Founder'
    }
  },
  emily: {
    title: i18n.t('pages.pricing.capterraReviews.emily.title'),
    description: i18n.t('pages.pricing.capterraReviews.emily.description'),
    link: 'https://www.capterra.com/p/204342/IdeaBuddy/reviews/3268991',
    user: {
      avatar: require('@/assets/img/pricing/capterra-reviews/emily.png'),
      name: 'Emily A.',
      title: 'Artist | CEO'
    }
  },
  john: {
    title: i18n.t('pages.pricing.capterraReviews.john.title'),
    description: i18n.t('pages.pricing.capterraReviews.john.description'),
    link: 'https://www.capterra.com/p/204342/IdeaBuddy/reviews/3252267',
    user: {
      avatar: require('@/assets/img/pricing/capterra-reviews/john.png'),
      name: 'John T.',
      title: 'Owner'
    }
  }
}
