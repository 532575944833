<template>
  <div class="idea-is-safe-wrapper">
    <div class="idea-is-safe">
      <div class="content-container">

        <div class="content d-flex flex-column flex-md-row">
          <div class="text-center">
            <img
              class="safebox"
              src="@/assets/img/pricing/idea-is-safe/safebox.png"
              alt="safebox"
            >
          </div>

          <div class="every-idea-is-safe">
            <p class="title font-outfit-semi-bold">
              {{ $t('pages.pricing.ideaIsSafe.everyIdeaIsSafeWithUs') }}
            </p>
            <p class="description">
              {{ $t('pages.pricing.ideaIsSafe.businessPlansShouldStaySecret') }}
            </p>
          </div>

          <div class="d-flex flex-column list-item-wrapper">
            <idea-is-safe-list-item
              :icon="require('@/assets/img/pricing/idea-is-safe/user-secret.svg')"
              :title="$t('pages.pricing.ideaIsSafe.personalData')"
              :description="$t('pages.pricing.ideaIsSafe.nobodyElseCanHaveAccess')"
            />
            <idea-is-safe-list-item
              :icon="require('@/assets/img/pricing/idea-is-safe/shield.svg')"
              :title="$t('pages.pricing.ideaIsSafe.confidentialityGuaranteed')"
            >
              <template #description>
                {{ $t('pages.pricing.ideaIsSafe.weGuaranteeThatWeWillKeepYouIdeasConfidential') }}
                <a
                  href="https://ideabuddy.com/terms-of-service"
                  class="cursor-pointer decoration-none terms-of-service"
                  target="_blank"
                >{{ $t('pages.pricing.ideaIsSafe.termsOfService') }}</a>
              </template>
            </idea-is-safe-list-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IdeaIsSafeListItem from '@/views/Payment/Components/Sections/IdeaIsSafe/IdeaIsSafeListItem'

export default {
  name: 'IdeaIsSafeSection',
  components: { IdeaIsSafeListItem }
}
</script>

<style scoped lang="scss">
.idea-is-safe-wrapper {
  padding: 120px 20px 60px;
  background-color: #F8FAFF;

  @include media-breakpoint-up($md) {
    padding: 60px 20px 50px;
  }

  .idea-is-safe {
    max-width: 1140px;
    margin: 0 auto;
    color: $color-white;

    .content-container {
      background-color: #081E4A;
      border-radius: 8px 8px 0 0;
      background-image: url('~@/assets/img/pricing/idea-is-safe/wave.png');
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: contain;
      padding: 0 20px 45px;

      @include media-breakpoint-up($md) {
        padding: 0 20px 30px;
      }

      @include media-breakpoint-up($lg) {
        padding: 0 22px 50px 0;
      }

      p {
        color: $color-white;
      }

      .content {
        column-gap: 10px;

        .safebox {
          width: 200px;
          margin: -60px 0 10px;

          @include media-breakpoint-up($md) {
            width: 148px;
            margin-top: 18px;
            margin-bottom: unset;
            margin-left: -26px;
          }

          @include media-breakpoint-up($lg) {
            width: 238px;
            margin-left: -20px;
          }
        }

        .title {
          font-size: 16px;
          line-height: 22px;
          margin: 0 0 10px;

          @include media-breakpoint-up($lg) {
            font-size: 26px;
            margin: 0 0 15px;
          }
        }

        .description {
          font-size: 14px;
          line-height: 21px;
          margin: 0;
        }

        .every-idea-is-safe {
          margin-bottom: 25px;

          @include media-breakpoint-up($md) {
            padding: 20px 0 0;
            margin-bottom: unset;
            width: 65%;
          }

          @include media-breakpoint-up($lg) {
            width: 50%;
            padding: 39px 0 0;
          }
        }

        .list-item-wrapper {
          row-gap: 23px;

          @include media-breakpoint-up($md) {
            padding: 20px 0 0 60px;
            row-gap: 10px;
          }

          @include media-breakpoint-up($lg) {
            padding: 39px 0 0 60px;
            row-gap: 32px;
          }
        }

        .terms-of-service {
          color: #00B7FF;
        }
      }
    }
  }

}
</style>
