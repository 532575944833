<template>
  <ib-dialog
    :visible="dialogThankYouVisible"
    width="550px"
    :show-close="false"
    empty-header
  >
    <ib-dialog-confirmation-content
      :image="require('@/assets/img/illustrations/dialogs/thank-you.svg')"
      :title="$t('dialogs.thankYou.title')"
    />
    <div class="text-center">
      <p class="extended success-text" :class="{'mb-0': !isLifetime}">
        {{ $t('dialogs.thankYou.subtitle') }}
      </p>
      <p v-if="!isLifetime" class="renewed-text">
        {{ $t('dialogs.thankYou.renewalText') }}
      </p>
    </div>

    <!-- Dialog Footer -->
    <template #footer>
      <ib-divider block class="mb-4" />
      <ib-dialog-footer
        button-size="md"
        :visible-cancel-btn="false"
        :submit-btn-text="$t('dialogs.thankYou.submit')"
        @submit="sendToHome"
      />
    </template>
    <!-- /Dialog Footer -->
  </ib-dialog>
</template>

<script>
import UserPlanEnum from '@/constants/UserPlanEnum'

export default {
  name: 'DialogThankYou',

  props: {
    dialogThankYouVisible: {
      type: Boolean,
      default: false
    },

    thankYouData: {
      type: Object,
      default: () => ({
        type: 'month',
        slug: UserPlanEnum.DREAMER
      })
    }
  },

  computed: {
    isLifetime () {
      return this.thankYouData.type === 'lifetime'
    }
  },

  methods: {
    sendToHome () {
      this.$emit('close-dialog')
    }
  }
}
</script>

<style scoped>
.success-text {
  font-size: 24px;
  opacity: 1;
}

.renewed-text {
  font-size: 12px;
  opacity: 0.5;
}
</style>
