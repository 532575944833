<template>
  <ib-dialog
    :visible="visible"
    :show-close="false"
    @close="closeDialog"
  >
    <ib-dialog-confirmation-content
      :image="require('@/assets/img/illustrations/dialogs/upgrade.svg')"
      :title="$t('dialogs.upgradePricePlan.pricePlanChange')"
    />

    <!-- Dialog Body -->
    <div class="o-50">
      <p class="text-lead m-0">
        <span>{{ $t('dialogs.upgradePricePlan.newPlan') }}: </span>
        <span>{{ isLifetime ? 'Lifetime Offer' : record.slug }}</span>
        /
        <span>
          {{ record.ideas }}
          <template v-if="record.ideas > 1">
            {{ $t('dialogs.upgradePricePlan.ideas') }}
          </template>
          <template v-else>
            {{ $t('dialogs.upgradePricePlan.idea') }}
          </template>
        </span>
        /
        <span v-if="!isLifetime">
          {{ record.type === 'month' ? $t('monthly') : $t('annually') }}
        </span>
        <span v-else class="text-capitalize">
          {{ $t('pages.pricing.lifetime') }}
        </span>
      </p>
      <p v-if="previewData && previewData.response && previewData.response.immediate_payment" class="text-lead m-0">
        <span>{{ $t('dialogs.upgradePricePlan.billedImmediately') }}: </span>
        <span>{{ previewData.response.immediate_payment.currency }} {{ previewData.response.immediate_payment.amount.toFixed(2) }}</span>
      </p>
      <p class="text-lead m-0">
        <span>{{ $t('dialogs.upgradePricePlan.newRate') }}: </span>
        <span class="price">
          <span v-if="record && record.price">
            {{ typeof record !== 'undefined' ? record.price.toString().replace('US', '').slice(0,-3) : 0 }}
            {{ typeof record !== 'undefined' ? record.price.toString().replace('US', '').slice(-3) === '.00' ? '' : record.price.toString().replace('US', '').slice(-3) : 0 }}
          </span>

          <span class="price-text">
            <template v-if="record.type === 'month'">
              {{ $t('dialogs.upgradePricePlan.perMonth') }}
            </template>

            <template v-if="record.type === 'year'">
              {{ $t('dialogs.upgradePricePlan.perYear') }}
            </template>
          </span>
        </span>
      </p>
      <p v-if="previewData && previewData.response && previewData.response.next_payment" class="text-lead m-0">
        <span>{{ $t('dialogs.upgradePricePlan.nextBillingDate') }}: </span>
        <span v-if="isLifetime">N/A</span>
        <span v-else>{{ $moment(previewData.response.next_payment.date).format('MMM Do, Y') }}</span>
      </p>
    </div>
    <!-- /Dialog Body -->

    <!-- Dialog Footer -->
    <template #footer>
      <ib-dialog-footer
        button-size="md"
        :cancel-btn-text="$t('notNow')"
        :submit-btn-text="$t('confirm')"
        :loading="loading"
        space-between-buttons
        @cancel="closeDialog"
        @submit="confirmDialog"
      />
    </template>
    <!-- /Dialog Footer -->

  </ib-dialog>

</template>

<script>
export default {
  name: 'DialogConfirm',

  props: {
    visible: {
      type: Boolean,
      default: false
    },

    previewData: {
      type: Object,
      default: () => ({})
    },

    record: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    isLifetime () {
      return this.record.type === 'lifetime'
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close-dialog')
    },

    confirmDialog () {
      this.$emit('confirm-dialog')
    }
  }
}
</script>
