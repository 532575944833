<template>
  <div
    class="feature-section-card-wrapper d-flex flex-column  align-items-center position-relative"
    :class="reverse ? 'flex-md-row-reverse' : 'flex-md-row'"
  >
    <div
      class="text-box-wrapper flex-grow-1"
      :class="reverse ? 'text-box-wrapper-revers' : null"
    >
      <div class="text-box">
        <template v-if="icon && name">
          <div class="title-wrapper d-flex align-items-center">
            <img
              :src="icon"
              alt="icon"
            >
            <p class="text-box-title font-outfit-semi-bold" :class="classColor">
              {{ name }}
            </p>
          </div>
        </template>

        <p class="text-box-subtitle font-outfit-semi-bold">
          {{ title }}
        </p>

        <p class="text-box-description">
          {{ description }}
        </p>
      </div>
    </div>
    <div class="video-wrapper">
      <video-player
        :video-name="videoName"
        muted
        :controls="false"
        autoplay
        loop
      />
    </div>
  </div>
</template>

<script>
import VideoPlayer from '@/components/_v2/VideoPlayer'

export default {
  name: 'FeatureSectionCard',

  components: {
    VideoPlayer
  },

  props: {
    icon: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    classColor: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    videoName: {
      type: String,
      required: true
    },
    reverse: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.feature-section-card-wrapper {
  column-gap: 44px;
  row-gap: 30px;
  text-align: center;
  margin-bottom: 70px;

  @include media-breakpoint-up($md) {
    text-align: left;
  }

  @include media-breakpoint-up($lg) {
    column-gap: 46px;
    row-gap: 60px;
    margin-bottom: 150px;
  }

  .text-box-wrapper {
    @include media-breakpoint-up($md) {
      max-width: 40%;
    }

    &.text-box-wrapper-revers {
      .text-box {
        @include media-breakpoint-up($md) {
          padding: 0 0 0 20%;
        }
      }
    }

    .text-box {
      @include media-breakpoint-up($md) {
        padding: 0 20% 0 0;
      }

      .title-wrapper {
        margin: 0 0 15px;
        column-gap: 10px;
        justify-content: center;

        @include media-breakpoint-up($md) {
          justify-content: left;
          margin: 0 0 20px
        }

        @include media-breakpoint-up($lg) {
          font-size: 20px;
        }

        img {
          width: 20px;

          @include media-breakpoint-up($lg) {
            width: 20px;
          }
        }

        .text-box-title {
          //color: #0E64E6;
          font-size: 18px;
          margin: 0;

        }
      }

      .text-box-subtitle {
        font-size: 20px;
        margin: 0 0 15px;

        @include media-breakpoint-up($md) {
          font-size: 20px;
          margin: 0 0 10px;
        }

        @include media-breakpoint-up($lg) {
          font-size: 26px;
        }
      }

      .text-box-description {
        margin: 0 0 25px;
        font-size: 14px;

        @include media-breakpoint-up($lg) {
          font-size: 16px;
        }
      }
    }
  }

  .video-wrapper {
    flex-grow: 1;
    padding: 1px;
    border-radius: 8px;
    box-shadow: 0 5px 30px rgba(8, 30, 74, 0.15);

    video {
      width: 550px;
    }
    @include media-breakpoint-up($md) {
      max-width: 60%;
    }
  }
}
</style>
