<template>
  <div>
    <lifetime-plans-section />
    <payment-guarantee-section
      :title="`${$t('pages.pricing.paymentGuarantee.daysMoneyBackGuaranteeNoQuestionsAsked', {days: '30'})}`"
      :description="`${$t('pages.pricing.paymentGuarantee.notFullySatisfiedWithIdeaBuddy', {days: '30', email: 'support@ideabuddy.com'})}`"
      :money-back-image="require('@/assets/img/pricing/payment-guarantee/30-days-badge-money-back.svg')"
    />
    <features-section />
    <ai-assistance-section />
    <benefits-section />
    <idea-is-safe-section />
    <capterra-reviews-section />
    <lifetime-faq-section />
  </div>
</template>

<script>
import AiAssistanceSection from '@/views/Payment/Components/Sections/AiAssistanceSection/AiAssistanceSection.vue'
import BenefitsSection from '@/views/Payment/Components/Sections/Benefit/BenefitsSection.vue'
import CapterraReviewsSection from '@/views/Payment/Components/Sections/CapterraReviews/CapterraReviewsSection'
import FeaturesSection from '@/views/Payment/Components/Sections/Features/FeaturesSection.vue'
import IdeaIsSafeSection from '@/views/Payment/Components/Sections/IdeaIsSafe/IdeaIsSafeSection'
import LifetimeFaqSection from '@/views/Payment/Components/Sections/Faq/LifetimeFaqSection'
import LifetimePlansSection from '@/views/Payment/Components/Sections/Plans/LifetimePlansSection'
import PaymentGuaranteeSection from '@/views/Payment/Components/Sections/PaymentGuarantee/PaymentGuaranteeSection'

export default {
  name: 'SelectLifetimePlan',

  components: {
    AiAssistanceSection,
    BenefitsSection,
    CapterraReviewsSection,
    FeaturesSection,
    IdeaIsSafeSection,
    LifetimeFaqSection,
    LifetimePlansSection,
    PaymentGuaranteeSection
  }
}
</script>
