<template>
  <div class="capterra-reviews-wrapper">
    <div class="capterra-reviews">
      <!-- Title & Subtitle -->
      <div class="title-and-subtitle">
        <div class="what-our-users-say d-flex align-items-end justify-content-center">
          <div class="position-relative">
            <img
              class="horn-img"
              src="@/assets/img/pricing/capterra-reviews/horn.svg"
              alt="horn"
            >
          </div>
          <p class="m-0 font-tragic-marker">
            {{ $t('pages.pricing.capterraReviews.whatOurUsersSay') }}
          </p>
        </div>
        <div class="text-center">
          <h1 class="font-outfit-semi-bold m-0">
            {{ $t('pages.pricing.capterraReviews.yourIdeasAreInGoodHands') }}
          </h1>
        </div>
      </div>
      <!-- Title & Subtitle -->

      <!-- Capterra Reviews -->
      <el-row :gutter="30" class="d-flex flex-wrap">
        <el-col :md="12" :lg="8" class="d-flex">
          <capterra-review-card
            class="w-100 d-flex"
            :title="capterraReviews.jonathan.title"
            :description="capterraReviews.jonathan.description"
            :link="capterraReviews.jonathan.link"
            :user="capterraReviews.jonathan.user"
          />
        </el-col>
        <el-col :md="12" :lg="8" class="d-flex">
          <capterra-review-card
            class="w-100 d-none d-md-flex"
            :title="capterraReviews.emily.title"
            :description="capterraReviews.emily.description"
            :link="capterraReviews.emily.link"
            :user="capterraReviews.emily.user"
          />
        </el-col>
        <el-col :md="12" :lg="8" class="d-flex">
          <capterra-review-card
            class="w-100 d-none d-lg-flex"
            :title="capterraReviews.john.title"
            :description="capterraReviews.john.description"
            :link="capterraReviews.john.link"
            :user="capterraReviews.john.user"
          />
        </el-col>
      </el-row>
      <!-- /Capterra Reviews -->
    </div>
  </div>
</template>

<script>
import CapterraReviewCard from '@/views/Payment/Components/Sections/CapterraReviews/CapterraReviewCard'
import capterraReviews from '@/data/capterraReviews'

export default {
  name: 'CapterraReviewsSection',

  components: {
    CapterraReviewCard
  },

  data () {
    return {
      capterraReviews
    }
  }
}
</script>

<style scoped lang="scss">
.capterra-reviews-wrapper {
  background-color: #F8FAFF;
  padding: 40px 20px 20px;

  .capterra-reviews {
    max-width: 1080px;
    margin: 0 auto;
    .title-and-subtitle {
      margin-bottom: 35px;

      @include media-breakpoint-up($md) {
        margin-bottom: 70px;
      }

      h1 {
        font-size: 30px;

        @include media-breakpoint-up($md) {
          font-size: 36px;
        }
      }
    }

    .what-our-users-say {
      margin-bottom: 25px;

      @include media-breakpoint-up($md) {
        font-size: 30px;
      }

      @include media-breakpoint-up($lg) {
        margin-bottom: 30px;
      }

      .horn-img {
        @include media-breakpoint-up($lg) {
          position: absolute;
          bottom: 10px;
          right: -15px;
        }
      }

      p {
        font-size: 24px;
        color: $color-primary;

        @include media-breakpoint-up($md) {
          font-size: 30px;
        }
      }
    }
  }
}
</style>
