<template>
  <div class="benefit-section-card-wrapper d-flex flex-column  align-items-center text-center">
    <img
      :src="image"
      alt="image"
    >
    <p class="card-title font-outfit-semi-bold">
      {{ title }}
    </p>
    <p class="card-text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'BenefitsSectionCard',

  props: {
    image: {
      type: String,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">

  .benefit-section-card-wrapper {
    width: 100%;
    max-width: 220px;
    margin-bottom: 30px;

    @include media-breakpoint-up($md) {
      text-align: left;
      width: 30%;
      max-width: 250px;
    }

    img {
      max-width: 220px;
    }

    .card-title {
      font-size: 20px;

      @include media-breakpoint-up($lg) {
        font-size: 26px;
      }
    }

    .card-text {
      font-size: 14px;

      @include media-breakpoint-up($lg) {
        font-size: 16px;
      }
    }
  }

</style>
