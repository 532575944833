<template>
  <div class="wrapper font-outfit-semi-bold">
    <span>{{ $t('pages.pricing.popular') }}</span>
  </div>
</template>

<script>
export default {
  name: 'PopularTag'
}
</script>

<style scoped lang="scss">
.wrapper {
  background-color: $color-yellow;
  position: absolute;
  top: -12px;
  right: 15px;
  border-radius: 0 0 8px 8px;
  font-size: 14px;
  text-transform: uppercase;
  color: #0A3999;
  padding: 2px 15px 3px;

  &:before {
    content: '';
    position: absolute;
    right: 100%;
    width: 0;
    height: 0;
    border-top: 11px solid transparent;
    border-right: 7px solid $color-yellow;
    filter: brightness(80%);
  }
}
</style>
