<template>
  <div class="bg-white">
    <div class="capterra-review-card d-flex flex-column justify-content-between">
      <div class="card-top-container">
        <!-- Stars & Capterra logo -->
        <div class="stars-and-logo d-flex justify-content-between align-items-center">
          <div>
            <img
              class="d-md-none"
              src="@/assets/img/pricing/capterra-reviews/stars-mobile.png"
              alt="stars"
            >
            <img
              class="stars d-none d-md-block"
              src="@/assets/img/pricing/capterra-reviews/stars.png"
              alt="stars"
            >
          </div>
          <div>
            <img
              class="capterra-logo"
              src="@/assets/img/pricing/capterra-reviews/capterra-logo.png"
              alt="capterra-logo"
            >
          </div>
        </div>
        <!-- /Stars & Capterra logo -->

        <!-- Title & Description -->
        <div class="mb-15">
          <p class="title font-outfit-semi-bold mt-0 mb-15">
            {{ title }}
          </p>
          <p class="description m-0 mb-3 mb-md-0">
            {{ description }}
          </p>
        </div>
        <!-- /Title & Description -->

        <!-- Read more -->
        <div class="read-more">
          <a
            class="font-outfit-semi-bold"
            :href="link"
            target="_blank"
          >
            {{ $t('pages.pricing.capterraReviews.readMore') }}
          </a>
        </div>
        <!-- /Read more -->
      </div>

      <div>
        <!-- User info -->
        <div class="d-flex align-items-center">
          <div class="mr-3">
            <img
              :src="user.avatar"
              :alt="user.name"
              width="52"
            >
          </div>
          <div class="d-flex flex-column justify-content-center">
            <span class="user-name font-outfit-semi-bold">{{ user.name }}</span>
            <span class="user-title">{{ user.title }}</span>
          </div>
        </div>
        <!-- /User info -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CapterraReviewCard',

  props: {
    title: {
      type: String,
      required: true
    },

    description: {
      type: String,
      required: true
    },

    link: {
      type: String,
      required: true
    },

    user: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.capterra-review-card {
  border: 1px solid rgba(187, 201, 228, 0.33);
  border-radius: 8px;
  padding: 31px 20px 20px;

  @include media-breakpoint-up($md) {
    padding: 32px 30px 30px;
  }

  .card-top-container {
    margin-bottom: 35px;
  }

  .stars-and-logo {
    margin-bottom: 18px;

    .stars {
      width: 118px;
      height: 18px;
    }

    .capterra-logo {
      width: 96px;
      height: 31px;

      @include media-breakpoint-up($md) {
        width: 107px;
        height: 34px;
      }
    }
  }

  .mb-15 {
   margin-bottom: 15px;
  }

  .title,
  .description {
    font-size: 16px;
    line-height: 24px;
  }

  .read-more {
    a {
      color: $color-primary;
      text-decoration: none;
      font-size: 16px;
    }
  }

  .user-name {
    font-size: 16px;
  }

  .user-title {
    font-size: 14px;
  }
}
</style>
