const data = {
  month: {
    Dreamer: {
      1: {
        $: 14.3,
        A$: 12.0,
        R$: 9.0,
        CA$: 14.0,
        '€': 12.0,
        '₹': 6.0,
        MX$: 7.5,
        NZ$: 11.2,
        '£': 13.8,
        ZAR: 7.5,
        TRY: 4.5
      },
      3: {
        $: 19.0,
        A$: 16.0,
        R$: 11.7,
        CA$: 18.7,
        '€': 16.0,
        '₹': 8.0,
        MX$: 10.0,
        NZ$: 14.9,
        '£': 18.4,
        ZAR: 10.0,
        TRY: 6.0
      },
      10: {
        $: 23.8,
        A$: 21.0,
        R$: 14.4,
        CA$: 24.5,
        '€': 20.0,
        '₹': 10.0,
        MX$: 12.5,
        NZ$: 19.6,
        '£': 23.0,
        ZAR: 12.5,
        TRY: 7.5
      },
      30: {
        $: 28.5,
        A$: 25.2,
        R$: 17.1,
        CA$: 29.4,
        '€': 24.0,
        '₹': 12.0,
        MX$: 15.0,
        NZ$: 23.5,
        '£': 27.6,
        ZAR: 15.0,
        TRY: 9.0
      }
    },
    Founder: {
      1: {
        $: 19.0,
        A$: 16.0,
        R$: 11.7,
        CA$: 18.7,
        '€': 18.0,
        '₹': 8.0,
        MX$: 10.0,
        NZ$: 14.9,
        '£': 18.4,
        ZAR: 10.0,
        TRY: 6.0
      },
      3: {
        $: 23.8,
        A$: 21.0,
        R$: 14.4,
        CA$: 24.5,
        '€': 22.0,
        '₹': 10.0,
        MX$: 12.5,
        NZ$: 19.6,
        '£': 23.0,
        ZAR: 12.5,
        TRY: 7.5
      },
      10: {
        $: 28.5,
        A$: 27.0,
        R$: 17.1,
        CA$: 28.0,
        '€': 26.0,
        '₹': 12.0,
        MX$: 15.0,
        NZ$: 25.2,
        '£': 27.6,
        ZAR: 15.0,
        TRY: 9.0
      },
      30: {
        $: 33.3,
        A$: 33.0,
        R$: 22.5,
        CA$: 33.6,
        '€': 32.0,
        '₹': 14.0,
        MX$: 17.5,
        NZ$: 30.8,
        '£': 34.5,
        ZAR: 17.5,
        TRY: 10.5
      }
    },
    TeamPro: {
      1: {
        $: 23.8,
        A$: 21.0,
        R$: 14.4,
        CA$: 24.5,
        '€': 22.0,
        '₹': 10.0,
        MX$: 12.5,
        NZ$: 19.6,
        '£': 23.0,
        ZAR: 12.5,
        TRY: 7.5
      },
      3: {
        $: 28.5,
        A$: 27.0,
        R$: 17.1,
        CA$: 28.0,
        '€': 26.0,
        '₹': 12.0,
        MX$: 15.0,
        NZ$: 25.2,
        '£': 27.6,
        ZAR: 15.0,
        TRY: 9.0
      },
      10: {
        $: 33.3,
        A$: 33.0,
        R$: 22.5,
        CA$: 33.6,
        '€': 32.0,
        '₹': 14.0,
        MX$: 17.5,
        NZ$: 30.8,
        '£': 34.5,
        ZAR: 17.5,
        TRY: 10.5
      },
      30: {
        $: 38.0,
        A$: 39.0,
        R$: 26.1,
        CA$: 42.0,
        '€': 36.6,
        '₹': 16.0,
        MX$: 20.0,
        NZ$: 36.4,
        '£': 39.4,
        ZAR: 20.0,
        TRY: 12.0
      }
    }
  },
  year: {
    Dreamer: {
      1: {
        $: 57.0,
        A$: 50.4,
        R$: 34.6,
        CA$: 58.8,
        '€': 48.0,
        '₹': 24.0,
        MX$: 30.0,
        NZ$: 47.0,
        '£': 55.2,
        ZAR: 30.0,
        TRY: 18.0
      },
      3: {
        $: 79.8,
        A$: 72.0,
        R$: 47.5,
        CA$: 84.0,
        '€': 72.0,
        '₹': 36.0,
        MX$: 42.0,
        NZ$: 67.2,
        '£': 82.8,
        ZAR: 42.0,
        TRY: 25.2
      },
      10: {
        $: 114.0,
        A$: 108.0,
        R$: 69.1,
        CA$: 126.0,
        '€': 96.0,
        '₹': 48.0,
        MX$: 60.0,
        NZ$: 100.8,
        '£': 110.4,
        ZAR: 60.0,
        TRY: 36.0
      },
      30: {
        $: 171.0,
        A$: 144.0,
        R$: 108.0,
        CA$: 168.0,
        '€': 144.0,
        '₹': 72.0,
        MX$: 90.0,
        NZ$: 134.4,
        '£': 165.6,
        ZAR: 90.0,
        TRY: 54.0
      }
    },
    Founder: {
      1: {
        $: 79.8,
        A$: 72.0,
        R$: 47.5,
        CA$: 75.6,
        '€': 72.0,
        '₹': 36.0,
        MX$: 42.0,
        NZ$: 67.2,
        '£': 82.8,
        ZAR: 42.0,
        TRY: 25.2
      },
      3: {
        $: 114.0,
        A$: 108.0,
        R$: 69.1,
        CA$: 109.2,
        '€': 96.0,
        '₹': 48.0,
        MX$: 60.0,
        NZ$: 100.8,
        '£': 110.4,
        ZAR: 60.0,
        TRY: 36.0
      },
      10: {
        $: 171.0,
        A$: 144.0,
        R$: 108.0,
        CA$: 168.0,
        '€': 144.0,
        '₹': 72.0,
        MX$: 90.0,
        NZ$: 134.4,
        '£': 165.6,
        ZAR: 90.0,
        TRY: 54.0
      },
      30: {
        $: 228.0,
        A$: 180.0,
        R$: 172.8,
        CA$: 218.4,
        '€': 192.0,
        '₹': 96.0,
        MX$: 120.0,
        NZ$: 168.0,
        '£': 220.8,
        ZAR: 120.0,
        TRY: 72.0
      }
    },
    TeamPro: {
      1: {
        $: 102.6,
        A$: 108.0,
        R$: 54.0,
        CA$: 100.8,
        '€': 96.0,
        '₹': 42.0,
        MX$: 60.0,
        NZ$: 100.8,
        '£': 110.4,
        ZAR: 60.0,
        TRY: 36.0
      },
      3: {
        $: 148.2,
        A$: 144.0,
        R$: 86.4,
        CA$: 151.2,
        '€': 144.0,
        '₹': 60.0,
        MX$: 90.0,
        NZ$: 134.4,
        '£': 165.6,
        ZAR: 90.0,
        TRY: 54.0
      },
      10: {
        $: 228.0,
        A$: 180.0,
        R$: 129.6,
        CA$: 218.4,
        '€': 216.0,
        '₹': 96.0,
        MX$: 120.0,
        NZ$: 168.0,
        '£': 220.8,
        ZAR: 120.0,
        TRY: 72.0
      },
      30: {
        $: 285.0,
        A$: 216.0,
        R$: 194.4,
        CA$: 285.6,
        '€': 276.0,
        '₹': 120.0,
        MX$: 150.0,
        NZ$: 201.6,
        '£': 276.0,
        ZAR: 150.0,
        TRY: 90.0
      }
    }
  },
  lifetime: {
    Founder: {
      1: {
        $: 118.8,
        A$: 108.0,
        R$: 71.1,
        CA$: 108.5,
        '€': 110.0,
        '₹': 49.5,
        MX$: 62.5,
        NZ$: 100.8,
        '£': 109.2,
        ZAR: 62.5,
        TRY: 37.5
      },
      3: {
        $: 156.8,
        A$: 147.0,
        R$: 103.5,
        CA$: 157.5,
        '€': 145.0,
        '₹': 59.5,
        MX$: 82.5,
        NZ$: 137.2,
        '£': 143.8,
        ZAR: 82.5,
        TRY: 49.5
      },
      10: {
        $: 232.8,
        A$: 225.0,
        R$: 143.1,
        CA$: 227.5,
        '€': 225.0,
        '₹': 79.5,
        MX$: 122.5,
        NZ$: 210.0,
        '£': 224.2,
        ZAR: 122.5,
        TRY: 73.5
      },
      30: {
        $: 337.3,
        A$: 330.0,
        R$: 179.1,
        CA$: 325.5,
        '€': 325.0,
        '₹': 99.5,
        MX$: 177.5,
        NZ$: 308.0,
        '£': 327.8,
        ZAR: 177.5,
        TRY: 160.5
      }
    }
  }
}

export default data
