<template>
  <v-app>
    <authentication-service-provider
      ref="authenticationServiceProvider"
      v-slot="{ signUp, signupSocial, goToLogin, loaders, forms, errorMessage }"
      @signup-success="onSignupSuccess"
      @signup-social-success="onSignupSuccess"
    >
      <!--      @next-component="$emit('next-component', $event)"-->
      <div class="wrapper">
        <locale-changer class="pb-3" />

        <div class="form-wrapper">
          <!-- Title -->
          <h1>
            {{ $t('auth.signUpPayment.title') }}
          </h1>
          <!-- /Title -->

          <!-- Alert -->
          <v-alert
            v-show="errorMessage"
            border="left"
            color="red"
            type="error"
          >
            {{ errorMessage }}
          </v-alert>
          <!-- /Alert -->

          <!-- Signup with Google -->
          <v-btn
            class="button-google"
            block
            x-large
            type="submit"
            elevation="0"
            outlined
            :disabled="loaders.socialSignup || loaders.signUp"
            :loading="loaders.socialSignup"
            color="secondary"
            @click="signupSocial('google')"
          >
            <img src="@/assets/img/logos/google.png" alt="google" class="mr-2">
            <span>{{ $t('auth.signUp.signUpWithGoogle') }}</span>
          </v-btn>
          <!-- Signup with Google -->

          <!-- Or -->
          <p class="line-through">
            {{ $t('auth.global.or') }}
          </p>
          <!-- /Or -->

          <validation-observer v-slot="{ invalid }">
            <v-form @submit.native.prevent="signUp()">
              <validation-provider
                v-slot="{ errors }"
                name="email"
                rules="required|email"
              >
                <v-text-field
                  v-model="forms.signUp.email"
                  :label="$t('auth.global.email')"
                  outlined
                  required
                  :disabled="loaders.signUp || loaders.socialSignup"
                  background-color="white"
                  :error-messages="errors"
                  :placeholder="$t('auth.global.enterEmail')"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="password"
                rules="required|min:6"
              >
                <v-text-field
                  v-model="forms.signUp.password"
                  :label="$t('auth.global.password')"
                  outlined
                  required
                  :disabled="loaders.signUp || loaders.socialSignup"
                  background-color="white"
                  :type="showPassword ? 'text' : 'password'"
                  :placeholder="$t('auth.global.enterPassword')"
                  :error-messages="errors"
                >
                  <template #append>
                    <span @click="showPassword = !showPassword">
                      <v-icon>
                        {{ showPassword ? icons.mdiEye : icons.mdiEyeOff }}
                      </v-icon>
                    </span>
                  </template>
                </v-text-field>
              </validation-provider>

              <v-btn
                block
                x-large
                color="primary"
                elevation="0"
                :disabled="loaders.signUp || loaders.socialSignup || invalid"
                :loading="loaders.signUp"
                @click="signUp()"
              >
                <span class="font-outfit-semi-bold button-continue">{{ $t('continue') }}</span>
              </v-btn>
            </v-form>
          </validation-observer>

          <!-- Terms & Conditions -->
          <p>{{ $t('auth.global.byContinuing') }} <a
            href="https://ideabuddy.com/terms-of-service/" target="_blank"
          >{{ $t('auth.global.termsAndConditions') }}</a> {{ $t('auth.global.and') }}
            <a href="https://ideabuddy.com/privacy-policy/" target="_blank">{{ $t('auth.global.privacyPolicy') }}</a>.
          </p>
          <!-- /Terms & Conditions -->

          <!-- Go To Login -->
          <p class="already-have">
            <span>{{ $t('auth.signUpPayment.alreadyHave') }}</span>
          &nbsp;
            <a @click="goToLogin">{{ $t('auth.signUp.logIn') }}</a>
          </p>
        <!-- /Go To Login -->
        </div>
      </div>
    </authentication-service-provider>
  </v-app>
</template>

<script>
import { mdiEye, mdiEyeOff } from '@mdi/js'
import AuthenticationServiceProvider from '@/components/ServiceProviders/AuthenticationServiceProvider.vue'
import LocaleChanger from '@/components/LocaleChanger.vue'

export default {
  name: 'SignUp',

  components: { AuthenticationServiceProvider, LocaleChanger },

  data () {
    return {
      showPassword: false,
      icons: { mdiEye, mdiEyeOff }
    }
  },

  mounted () {
    this.$refs.authenticationServiceProvider.onMounted()
  },

  methods: {
    onSignupSuccess () {
      this.$emit('next-component', this.$refs.authenticationServiceProvider.getNextComponent())
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  background: #F5F6FA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #292f4d;

  .form-wrapper {
    width: 100%;
    max-width: 500px;

    h1 {
      max-width: 450px;
      margin: 20px auto 40px;
      font-size: 26px;

      @include media-breakpoint-up($sm) {
        font-size: 32px;
      }
    }

    p {
      font-size: 16px;
    }

    .line-through {
      overflow: hidden;
      text-align: center;

      &:before,
      &:after {
        background-color: rgba(41, 47, 77, 0.09);
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
      }

      &:before {
        right: 1rem;
        margin-left: -50%
      }

      &:after {
        left: 1rem;
        margin-right: -50%;
      }
    }

    span {
      text-transform: none;
    }

    img {
      width: 19px;
    }

    .button-continue {
        text-transform: uppercase;
    }

    .button-google {
      background: white;

      span {
        color: #292f4d;
      }
    }

    .already-have {
      margin-top: 60px;
    }
  }
}
</style>
