<template>
  <div class="plans-wrapper">
    <div class="intro-wrapper">
      <div class="period-switch-container m-auto text-center">
        <h1 class="header font-outfit-semi-bold d-lg-none text-center">
          {{ $t('pages.pricing.simpleAndAffordable') }}
          {{ $t('pages.pricing.pricePlans') }}
        </h1>
        <h1 class="header font-outfit-semi-bold d-none d-lg-block text-center">
          {{ $t('pages.pricing.simpleAndAffordable') }}
          <underlined-text class="px-1">
            {{ $t('pages.pricing.pricePlans') }}
          </underlined-text>
        </h1>

        <p class="subheader font-outfit-medium mt-0">
          {{ $t('pages.pricing.allPlansComeWithA15DayMoneyBackGuarantee') }}
        </p>

        <div class="switch-wrapper mx-auto position-relative">
          <form-switcher
            v-model="period"
            size="md"
            variant="blue"
            :disabled="loading"
            :options="periodOptions"
          />
          <div
            class="arrow-text d-flex font-tragic-marker align-items-start"
            :class="arrowTextClass"
          >
            <img src="@/assets/img/pricing/plans/arrow.svg" alt="arrow">
            <p>{{ arrowText }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="cards-wrapper text-center mt-lg-0">
      <el-row :gutter="14" class="cards-container d-flex flex-wrap m-auto text-center">
        <el-col
          v-for="plan in plans"
          :key="plan.title"
          :md="8"
          :class="{ 'mt-md-3 mt-lg-0': !plan.popular }"
          class="mb-lg-0"
        >
          <plan-card
            v-model="cardValues[period][plan.slug]"
            class="plan-card mb-md-0"
            :title="plan.title"
            :plan="getPlan(plan.slug)"
            :popular="plan.popular"
            :description="plan.description"
            :price="getPlan(plan.slug) ? getPlan(plan.slug).price : null"
            :monthly-price="getMonthlyPrice(plan.slug)"
            :currency-symbol="getPlan(plan.slug) ? getPlan(plan.slug).currency : null"
            :period="period"
            :options="plansOptions"
            :loading="loading"
            :button-loading="plan.slug === loadingPlanSlug"
            :disabled-features="plan.disabledFeatures"
            @choose-plan="value => onChoosePlan(plan.slug, value)"
            @contact="onContact"
          />
        </el-col>
      </el-row>
      <p class="prices-info">
        {{ $t('pages.pricing.pricesMayBeSubjectToAdditionalVatDependingOnYourCountry') }} 👆
      </p>
    </div>

    <!-- Dialogs -->
    <dialog-enterprise
      :enterprise-visible="dialogEnterpriseVisible"
      @close-enterprise="dialogEnterpriseVisible = false"
    />

    <dialog-confirm
      ref="dialogConfirm"
      :visible="dialogConfirm"
      :preview-data="previewData"
      :record="chosenData"
      @close-dialog="dialogConfirm = false"
      @confirm-dialog="confirmedUpdatePlan"
    />

    <dialog-thank-you
      :dialog-thank-you-visible="dialogThankYouVisible"
      :thank-you-data="chosenData"
      @close-dialog="closeDialog"
    />
    <!-- /Dialogs -->
  </div>
</template>

<script>
import DialogConfirm from '@/views/Payment/Dialogs/DialogConfirm'
import DialogEnterprise from '@/views/Payment/Dialogs/DialogEnterprise'
import DialogThankYou from '@/views/Payment/Dialogs/DialogThankYou'
import FormSwitcher from '@/components/_v2/Form/FormSwitcher'
import MixinPaddle from '@/mixins/paddle'
import MixinPartnero from '@/mixins/partnero'
import PlanCard from '@/views/Payment/Components/Sections/Plans/PlanCard'
import UnderlinedText from '@/components/_v2/UnderlinedText'
import { mapGetters } from 'vuex'
import { paymentQueryHelper } from '@/helpers/paymentQueryHelper'

import { formatPrice, getPriceInEuro } from '@/helpers/currencyHelper'

export default {
  name: 'PlansSection',

  components: {
    DialogConfirm,
    DialogEnterprise,
    DialogThankYou,
    FormSwitcher,
    PlanCard,
    UnderlinedText
  },

  mixins: [
    MixinPaddle,
    MixinPartnero
  ],

  data () {
    return {
      loading: false,
      updatedPrices: false,
      loadingPlanSlug: null,
      dialogConfirm: false,
      dialogEnterpriseVisible: false,
      dialogThankYouVisible: false,
      previewData: {},
      chosenData: {},
      cardValues: {
        monthly: {
          Dreamer: 1,
          Founder: 3,
          TeamPro: 10
        },
        annual: {
          Dreamer: 1,
          Founder: 3,
          TeamPro: 10
        },
        lifetime: {
          Dreamer: 1,
          Founder: 3,
          TeamPro: 10
        }
      },
      period: 'annual',
      periodOptions: [
        {
          title: this.$t('pages.pricing.monthly'),
          value: 'monthly'
        },
        {
          title: this.$t('pages.pricing.annual'),
          value: 'annual'
        }
      ],
      allPlans: [],
      plansOptions: [1, 3, 10, 30]
    }
  },

  computed: {
    ...mapGetters('user', [
      'isSubscribed',
      'getCurrentUser'
    ]),
    arrowText () {
      const texts = {
        monthly: this.$t('pages.pricing.arrowTexts.monthly'),
        annual: this.$t('pages.pricing.arrowTexts.annual'),
        lifetime: this.$t('pages.pricing.arrowTexts.lifetime')
      }

      return texts[this.period]
    },
    arrowTextClass () {
      return `arrow-text-${this.period}`
    },
    plans () {
      return [
        {
          title: 'Dreamer',
          slug: 'Dreamer',
          description: this.$t('pages.pricing.plansDescriptions.dreamer'),
          popular: false,
          price: 55,
          disabledFeatures: [10, 11, 12]
        }, {
          title: 'Founder',
          slug: 'Founder',
          description: this.$t('pages.pricing.plansDescriptions.founder'),
          popular: true,
          price: 95,
          disabledFeatures: [12]
        }, {
          title: 'Team Pro',
          slug: 'TeamPro',
          description: this.$t('pages.pricing.plansDescriptions.teamPro'),
          popular: false,
          price: 1,
          disabledFeatures: null
        }
      ]
    }
  },

  watch: {
    updatedPrices: {
      handler (newValue) {
        if (newValue) {
          const planInfo = paymentQueryHelper(false, this.$route.query)
          if (planInfo && planInfo.type) {
            this.period = planInfo.type
            const plan = this.capitalizeFirstLetter(planInfo.plan)
            this.cardValues[this.period][plan] = planInfo.noi
          }

          // Open dialog for checkout
          if (planInfo) {
            const foundPlan = this.findPlan(planInfo.plan, this.period, planInfo.noi)
            this.choosePlan(foundPlan)
          }
        }
      },
      immediate: true
    }
  },

  created () {
    this.loading = true
    this.setupPaddle(this.getPlans)
  },

  methods: {
    getPlans () {
      this.$http.get('provider/plan').then(response => {
        this.allPlans = response.data.payload

        this.updatePlansPrices()
      })
    },
    onChoosePlan (slug, val) {
      const ideas = this.cardValues[this.period][slug]
      const foundPlan = this.findPlan(slug, this.period, ideas)

      this.choosePlan(foundPlan)
    },
    updatePlansPrices () {
      const plansPrices = []

      this.allPlans.forEach(plan => {
        // eslint-disable-next-line no-undef
        Paddle.Product.Prices(plan.plan_id, (prices) => {
          plansPrices.push(prices)
          const { price, currency } = formatPrice(prices.price.net)

          plan.price = price
          plan.currency = currency

          if (plansPrices.length === this.allPlans.length) {
            this.loading = false
            this.updatedPrices = true
          }
        })
      })
    },
    onContact () {
      this.dialogEnterpriseVisible = true
    },
    choosePlan (plan) {
      this.loadingPlanSlug = plan.slug
      this.selectedPlan = plan
      if (this.isSubscribed &&
        this.$store.state.user.subscription.status !== 'deleted' &&
        !this.$store.state.user.freeTrial) {
        this.triggerPayment(plan)

        return
      }

      this.triggerPaddle(plan)
    },
    getPlan (planSlug, period) {
      if (planSlug === 'Team') {
        return null
      }

      if (!period) period = this.period

      const ideas = this.cardValues[this.period][planSlug]
      const foundPlan = this.findPlan(planSlug, period, ideas)

      if (foundPlan) {
        return {
          currency: foundPlan.currency,
          price: this.period === 'annual' ? Math.round((foundPlan.price / 12)) : Math.round(foundPlan.price),
          aiRequests: foundPlan.ai_requests
        }
      }

      return null
    },
    getMonthlyPrice (planSlug) {
      const ideas = this.cardValues[this.period][planSlug]
      const foundPlan = this.findPlan(planSlug, 'monthly', ideas)

      return foundPlan ? foundPlan.price : null
    },
    findPlan (planSlug, period, ideas) {
      const periodTypes = {
        monthly: 'month',
        annual: 'year',
        lifetime: 'lifetime'
      }
      const type = periodTypes[period]

      return this.allPlans.find(plan => plan.slug.toLowerCase() === planSlug.toLowerCase() && plan.ideas === ideas && plan.type === type)
    },
    triggerPayment (plan) {
      if (this.$store.state.user.subscription.id === plan.id) {
        this.$notify({ message: 'You already have this plan!', type: 'error' })
        this.loadingPlanSlug = null
      } else {
        this.$http.post(
          'provider/update-plan',
          { ...plan, confirmed: this.confirmed }
        ).then((response) => {
          if (response.status === 200) {
            this.previewData = response.data.payload
            this.dialogConfirm = true
            this.loadingPlanSlug = null
            this.chosenData = Object.assign({}, plan)

            if (this.confirmed) {
              this.$refs.dialogConfirm.loading = false
              this.$store.commit('user/setSubscription', response.data.payload)
              this.$intercom.trackEvent('Updated subscription')
              this.dialogThankYouVisible = true
              this.dialogConfirm = false
            }

            if (!this.previewData.success) {
              this.previewData = {}
              this.dialogConfirm = false
              this.triggerPaddle(plan)
            }
          }
        })
      }
    },
    triggerPaddle (plan) {
      if (this.$store.state.user.subscription.status !== 'deleted' && this.$store.state.user.subscription.id === plan.id) {
        this.$notify({ message: 'You already have this plan!', type: 'error' })
        this.loadingPlanSlug = null
      } else {
        const userPlanInfo = plan.slug + plan.ideas + (plan.type === 'month' ? 'Monthly' : 'Annual')
        const startUserPlan = userPlanInfo + 'Start'

        this.$gtm.trackEvent({
          event: startUserPlan
        })

        const $this = this
        const referral = window.Rewardful && window.Rewardful.referral
        this.createPartneroCustomer(this.getCurrentUser)

        // eslint-disable-next-line no-undef
        Paddle.Checkout.open({
          product: plan.plan_id,
          email: this.$store.state.user.email,
          customData: { customer_key: this.$store.state.user.email },
          passthrough: JSON.stringify({ userId: this.$store.state.user.id, rewardful: { referral: referral } }),
          locale: localStorage.getItem('lang'),
          successCallback: (data) => {
            this.$http.post('provider/select-plan', plan).then((response) => {
              if (response.status === 200) {
                // Send GTM events
                const successCheckout = 'checkout' + userPlanInfo + 'Success'
                $this.$gtm.trackEvent({
                  event: successCheckout
                })
                // Send GTM Purchase event
                $this.$gtm.trackEvent({
                  event: 'Purchase (New)',
                  value: getPriceInEuro([plan.type, plan.slug, plan.ideas, plan.currency]),
                  email: this.$store.state.user.email,
                  phone: 'Phone',
                  address: 'Address'
                })

                this.$intercom.update({
                  PlanType: `${plan.type.charAt(0).toUpperCase()}${plan.type.slice(1)}`,
                  PlanName: plan.slug,
                  PlanExpiry: response.data.payload.subscription.nextBillDate,
                  MaxIdeas: response.data.payload.subscription.ideas,
                  subscription_updated_at: this.$moment().unix()
                })
                this.$intercom.trackEvent('New subscription')

                this.$store.commit('user/setSubscription', response.data.payload)
                this.chosenData = Object.assign({}, plan)
                this.dialogThankYouVisible = true
              }
            })
          },
          loadCallback: () => {
            this.loadingPlanSlug = null
          },
          closeCallback: function (data) {
            this.loadingPlanSlug = null
            // Send GTM events
            const closeCheckout = 'checkout' + userPlanInfo + 'Close'
            $this.$gtm.trackEvent({
              event: closeCheckout
            })
          }
        })
      }
    },
    formatPrice (price) {
      return price.toString()
        .slice(0, -3)
        .replace(/\D/g, '')
    },
    confirmedUpdatePlan () {
      this.$refs.dialogConfirm.loading = true
      this.confirmed = true
      this.triggerPayment(this.selectedPlan)
    },
    closeDialog () {
      this.dialogThankYouVisible = false
      this.$router.push({ name: 'home' })
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  }
}
</script>

<style scoped lang="scss">
.plans-wrapper {
  .intro-wrapper {
    padding-top: 50px;
    background-color: #F8FAFF;
    background-image: url('~@/assets/img/pricing/plans/waves.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
  }

  .period-switch-container {
    max-width: 1140px;
    padding: 0 20px 50px;

    @include media-breakpoint-up($md) {
      padding-bottom: 105px;
    }

    @include media-breakpoint-up($lg) {
      padding-bottom: 111px;
    }

    .header {
      margin: 0 0 10px;
      font-size: 30px;

      @include media-breakpoint-up($lg) {
        font-size: 36px;
      }
    }

    .subheader {
      font-size: 16px;
      margin-bottom: 30px;
    }

    .switch-wrapper {
      max-width: 224px;

      .arrow-text {
        position: absolute;
        right: 0;
        margin-top: 18px;
        white-space: nowrap;

        @include media-breakpoint-up($md) {
          right: -45px;
          margin-top: 12px;
        }

        &-monthly {
          left: 25%;
          right: unset;
        }

        &-monthly,
        &-annual {
          img {
            transform: rotate(25deg);

            @include media-breakpoint-up($md) {
              transform: unset;
            }
          }
        }

        &-annual {
          right: 25%;
          flex-direction: row-reverse;

          img {
            transform: scaleX(-1) rotate(25deg);

            @include media-breakpoint-up($md) {
              transform: scaleX(-1);
            }
          }
        }

        p {
          color: #0E64E6;
          font-size: 20px;
          padding-top: 9px;

          @include media-breakpoint-up($md) {
            font-size: 24px;
            margin: 3px 11px 0;
          }
        }
      }

      ::v-deep {
        .form-switch-wrapper {
          background: $color-white;
          border: 1px solid #bbc9e4;
          border-radius: 48px;
          font-size: 12px;
          text-transform: uppercase;
        }

        .switch-input.active + .switch-label.form-switcher-blue {
          color: white;
        }

        .switch-label {
          font-family: $outfit-medium;
          color: #081E4A;
        }
      }
    }
  }

  .cards-wrapper {
    margin-top: 70px;

    @include media-breakpoint-up($md) {
      margin-top: 20px;
    }

    .prices-info {
      margin-top: 10px;

      @include media-breakpoint-up($lg) {
        margin-top: 50px
      }
    }

    .cards-container {
      max-width: 910px;
      padding: 0 13px;

      .el-col {
        margin-bottom: 20px;
      }

      ::v-deep {
        @include media-breakpoint-between($md, $lg) {
          .card-wrapper.popular {
            .plan-title {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
