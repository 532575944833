<template>
  <svg class="loader-payment-wrapper">
    <circle class="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
  </svg>
</template>

<script>
export default {
  name: 'LoaderPayment'
}
</script>

<style scoped lang="scss">
.loader-payment-wrapper {
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;

  & .path {
    stroke: $color-primary;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
}
</style>
