<template>
  <li class="active font-outfit-medium">
    <ib-tooltip :content="`${$t('pages.pricing.featuresTexts.aiRequests')}`" :open-delay="0" :dark="!dark">
      <span>{{ numOfRequests }} {{ $t('pages.pricing.featuresName.aiRequests') }}
        <img
          src="@/assets/img/illustrations/payment/ai-requests-icon.svg"
          alt="ai-requests"
        >
      </span>
    </ib-tooltip>
  </li>
</template>

<script>
export default {
  name: 'PlanCardAiRequests',

  props: {
    numOfRequests: {
      validator: value => typeof value === 'number' || value === null,
      required: true
    },

    dark: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">

</style>
