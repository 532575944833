<template>
  <div class="wrapper d-flex" :class="{'disabled': disabled}">
    <div class="button d-flex justify-content-center align-items-center" :class="{'disabled': isMinusDisabled || disabled}" @click="onMinus">
      <img src="@/assets/img/pricing/plans/minus.svg" alt="stars">
    </div>
    <div class="number d-flex justify-content-center align-items-center font-outfit-semi-bold">
      <span>{{ value }}</span>
    </div>
    <div class="button d-flex justify-content-center align-items-center" :class="{'disabled': isPlusDisabled || disabled}" @click="onPlus">
      <img src="@/assets/img/pricing/plans/plus.svg" alt="stars">
    </div>
  </div>
</template>

<script>
import _indexOf from 'lodash/indexOf'

export default {
  name: 'InputNumber',

  props: {
    value: {
      type: Number,
      default: 1
    },
    options: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      content: this.value
    }
  },

  computed: {
    activeIndex () {
      return _indexOf(this.options, this.value)
    },

    isMinusDisabled () {
      return this.activeIndex === 0
    },

    isPlusDisabled () {
      return this.activeIndex === this.options.length - 1
    }
  },

  watch: {
    content: {
      handler () {
        if (!this.disabled) {
          this.onChange()
        }
      },
      immediate: true
    },
    value: {
      handler (value) {
        this.content = value
      },
      immediate: true
    }
  },

  methods: {
    onMinus () {
      if (this.options) {
        const optIndex = this.options.indexOf(this.value)
        const prevIndex = optIndex - 1

        if (optIndex !== 0) {
          this.content = this.options[prevIndex]
        }

        return
      }

      this.content -= 1
    },

    onPlus () {
      if (this.options) {
        const optIndex = this.options.indexOf(this.value)
        const nextIndex = optIndex + 1

        if (nextIndex !== this.options.length) {
          this.content = this.options[nextIndex]
        }

        return
      }

      this.content++
    },

    onChange () {
      this.$emit('input', this.content)
      this.$emit('change', this.content)
    }
  }
}
</script>

<style scoped lang="scss">
$tmp-color-silver: #d3d9e1;

.wrapper {
  border: 1px solid $tmp-color-silver;
  border-radius: 10px;
  box-sizing: border-box;
  height: 42px;
  overflow: auto;

  .number {
    background-color: $color-white;
    width: 40%;
    font-size: 24px;
    border-left: 1px solid $tmp-color-silver;
    border-right: 1px solid $tmp-color-silver;
  }

  &.disabled {
    opacity: 0.6;
  }

  .button {
    background-color: #f3f7ff;
    width: 30%;
    font-size: 20px;
    user-select: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:not(.disabled) {
      &:hover {
        background: #ddeaff !important;
      }
    }

    img {
      transition: all 0.2s ease-in-out;
    }

    &.disabled {
      cursor: not-allowed;

      img {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }
    }
  }
}
</style>
